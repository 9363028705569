import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/TypeIn/MatchDots";

const json = {
  1: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P61-E1",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page61/Key/E1answerKey.png",
    component: T6v2,
    maxLength: 70,
    inputSize: 700,
    stylesTextInput: {
      background: "none",
      // border: 'none',
      // textAlign: 'center',
      fontSize: "22px",
      // fontWeight: "bold",
      borderBottom: "2px dotted",
    },
    titleQuestion: [
      {
        num: "1",

        title: `
        Order the words to make sentences with <i>used to</i>. There is one extra word.
	
									`,
        color: "#4b3a94",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
      <div style='line-height: 50px'>
        <div style='font-size: 22px'>
        used / lived / she / here / to / live <br>
        <u><b style='color: gray'>&ensp;She used to live here.&ensp;&ensp;&ensp;&ensp;&ensp;</b></u>
        </div>
        <div style='line-height:50px, font-size: 22px'>
          <b>1</b>&ensp; didn’t / to / they / English / used / speak / use <br>
          #.
        </div>

        <div style='line-height:50px, font-size: 22px'>
          <b>2</b>&ensp; use / to / my / used / badminton / cousin / play <br>
          #.
        </div>

        <div style='line-height:50px, font-size: 22px'>
          <b>3</b>&ensp; use / eat / to / don’t / we / Japanese food / didn’t <br>
          #.
        </div>

        <div style='line-height:50px, font-size: 22px'>
          <b>4</b>&ensp; you / use / study / did / to / Italian / used / ? <br>
          #
        </div>

        

      </div>
									`,
        answer: [
          `They didn't use to speak English`,
          `My cousin used to play badminton`,
          `We didn't use to eat Japanese food`,
          `Did you use to study Italian?`,
        ],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 1",
    id: "FP8-WB-demo-2023-U1-P9-E2",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page61/Key/E2answerKey.png",
    stylesTextInput: {},
    inputSize: 540,
    component: T6v2,
    isHiddenCheck: true,
    // totalInput: 2,
    titleQuestion: [
      {
        num: "2",
        title: `Tick (✔) the three sentences that describe past habits or states.
         Then rewrite the three sentences using <i>used to</i>. `,
        color: "#4b3a94",
      },
    ],
    selectStyle: {
      border: "solid 1px black",
      marginLeft: 10,
      height: 37,
      width: 37,
      textAlign: "center",
    },
    selectOptionRandom: true,
    selectOptionValues: ["✔", " "],
    questionImage: [],
    questions: [
      {
        title: `
			<img style='margin-left:100px; max-width:75%;margin-bottom:10px' src="img/FriendsPlus/Page61/E1/1.jpg"/>
			
			<div style='position: relative;'>
				
					<div style='display:flex'>
						<b>1</b>&ensp;We didn’t come here often.<select id=5></select>
					</div>
						#
					<div style='display:flex'>
						<b>2</b>&ensp;When did your brother leave school? <select id=6></select>
					</div>
						#
					<div style='display:flex'>
						<b>3</b>&ensp; My dad worked at that company. <select id=7></select>
					</div>
							#
				<div style=" position: absolute; top: 0px; left:587px; width:15cm ">						
					<div style='display:flex'>
						<b>4</b>&ensp;I met my best friend six years ago. <select id=8></select>
					</div>
						#
					<div style='display:flex'>
						<b>5</b>&ensp;Was that building a museum? <select id=9></select>
					</div>
						#
				</div>
			</div>
			
			`,
        answer: [
          `We didn't use to come here often.`,
          ` `,
          `My dad used to work at that company.`,
          ` `,
          `Did that building use to be a museum?`,
          `✔`,
          ` `,
          `✔`,
          ` `,
          `✔`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 8",
    id: "FP8-SB-demo-2023-U8-P61-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page61/Key/E3answerKey.png",
    component: T6,
    isHiddenCheck: true,
    inputSize: 700,
    stylesTextInput: {
      background: "none",
      // border: 'none',
      // textAlign: 'center',
      fontSize: "22px",
      // fontWeight: 'bold',
      borderBottom: "2px dotted",
    },
    titleQuestion: [
      {
        num: "3",
        title: `
        Correct the mistakes in bold.
					`,
        color: "#4b3a94",
      },
    ],
    questions: [
      {
        title: `
      <div style='line-height:50px; font-size: 22px'>
        <div>
        <b> Y̶̶o̶̶u̶ ̶w̶̶e̶̶r̶̶e̶</b> chatting online last night? <br>
        <u><b style='color: gray'>&ensp;Were you chatting online last night?&ensp;</b></u>
        </div>
        <div>
          <b>1</b>&ensp; Sergio was <b>write</b> a text message. <br>
          #
        </div>

        <div>
          <b>2</b>&ensp; We <b>wasn’t</b> playing basketball. <br>
          #
        </div>

        <div>
          <b>3</b>&ensp; Who <b>sitting</b> there? <br>
          #
        </div>

        <div>
          <b>4</b>&ensp; Liam <b>were</b> reading the newspaper. <br>
          #
        </div>

        <div>
        <b>5</b>&ensp; They <b>were a party having</b> at their house. <br>
        #
      </div>

        

      </div>
									`,
        answer: [
          `Sergio was writing a text message.`,
          `We weren't playing basketball.`,
          `Who was sitting there?`,
          `Liam was reading the newspaper.`,
          `They were having a party at their house.`,
        ],
      },
    ],
  },
  4: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P61-E4",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page61/Key/E4answerKey.png",
    component: T6v2,
    maxLength: 20,
    inputSize: 180,
    stylesTextInput: {
      background: "none",
      // border: 'none',
      // borderBottom: '1px solid red',
      textAlign: "center",
      fontSize: "22px",
      fontWeight: "bold",
      borderBottom: "2px dotted",
    },
    titleQuestion: [
      {
        num: "4",

        title: `
        Complete the dialogue using the past simple or past continuous form of the verbs in brackets.
         
								`,
        color: "#4b3a94",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
    <div style='line-height:50px; font-size: 22px'>
        <div style='display:flex'> <b>Hazel</b> <span style='margin-left:20px'>I <b><u style='color: gray'>&ensp;rang&ensp;</u></b> (ring) you last night, but you <sup>1</sup> # (not answer). <sup>2</sup> # (you / be) out?</span></div>

        <div style='display:flex'> <b>Leah</b> <span style='margin-left:20px'>Oh, I’m sorry. I think I <sup>3</sup> # (listen) to music when you <sup>4</sup> # (call). I <sup>5</sup> # (not know) you <sup>6</sup> # (want) to talk.</span></div>

        <div style='display:flex'> <b>Hazel</b> <span style='margin-left:20px'>It’s OK. I <sup>7</sup> # (have) a question about our homework, but I <sup>8</sup> # (phone) Alisha instead.</span></div>

        <div style='display:flex'> <b>Leah</b> <span style='margin-left:20px'>I <sup>9</sup> # (think) she <sup>10</sup> # (see) a film with her friends last night?</span></div>

        <div style='display:flex'> <b>Hazel</b> <span style='margin-left:20px'>No, she <sup>11</sup> # (not be). She and her sister <sup>12</sup> # (relax) at home.</span></div>

        <div style='display:flex'> <b>Leah</b> <span style='margin-left:20px'>Oh. Next time, send me a text message and then I’ll listen out for your call!</span></div>

        <div style='display:flex'> <b>Hazel</b> <span style='margin-left:20px'>Thanks – I will!</span></div>
    </div>
		
	
			
								`,
        answer: [
          `didn't answer`,
          `Were you`,
          `was listening`,
          `called`,
          `didn't know`,
          `wanted`,
          `had`,
          `phoned`,
          `thought`,
          `was seeing`,
          `wasn't`,
          `were relaxing`,
        ],
      },
    ],
  },
  5: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P61-E5",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6v2,
    // maxLength: 1,
    inputSize: 500,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "5",
        title: `
        Work in pairs. Which of these school issues are the same now as in the
        past? Which will be different in the future? Use can, can’t, could, couldn’t or will / won’t
        be able to
        `,
        color: "#4b3a94",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style="display: flex; flex-wrap: wrap; gap: 40px;margin-top:30px">

          <div>
            <span style="font-size: 25px;font-weight:bold;margin-right:10px">1</span>
            <span style="font-size: 25px">choice of subjects</span>
          </div>

          <div>
            <span style="font-size: 25px;font-weight:bold;margin-right:10px">2</span>
            <span style="font-size: 25px">ways of learning</span>
          </div>

          <div>
            <span style="font-size: 25px;font-weight:bold;margin-right:10px">3</span>
            <span style="font-size: 25px">school uniforms</span>
          </div>

          <div>
            <span style="font-size: 25px;font-weight:bold;margin-right:10px">4</span>
            <span style="font-size: 25px">class size</span>
          </div>

          <div>
            <span style="font-size: 25px;font-weight:bold;margin-right:10px">5</span>
            <span style="font-size: 25px">rules</span>
          </div>

        </div>

        <div style="margin-left: 100px;margin-top: 50px"><img src="img/FriendsPlus/Page61/11.jpg"/></div>


        `,
        answer: [],
      },
    ],
  },
};

export default json;
