import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";

const json = {
  1: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P28-E8",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page28/E1/Key/E1answerkey.png",
    component: T6v2,
    inputSize: 200,
    maxLength: 20,
    stylesTextInput: {
      borderStyle: "dotted",
      fontSize: "23px",
      textAlign: "center",
      marginLeft: "-13px",
    },
    titleQuestion: [
      {
        num: "1",
        title: `
				Match the words with photos 1–7.
				`,
        color: "rgb(246,127,34)",
        star: 1,
      },
    ],
    textareaStyle: {
      width: 400,
    },
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div>
          

          <div style='display: flex; flex-direction: column; justify-content: center; align-items: center;'>
            <div style='display: flex; flex-direction:column;flex-wrap: wrap; gap: 10px; align-items: center; justify-content: center;
              max-width: 900px; padding: 10px 80px; border-radius: 20px; border: 3px solid rgb(246,127,34);'>
             
              <div>
              <span>automatic &emsp; home-made &emsp; multi-functional</span> 
              </div>

              <div>
              <span style="text-decoration: line-through;">natural </span> &emsp; <span>second-hand &emsp;  smart &emsp; solar &emsp; waterproof</span>
              </div>
           
            
             
            </div>

            <div style="display:flex;flex-direction:column; text-align: center;">


            <div style="display:flex;flex-direction:row;margin-top:40px">
            <div style="display:flex;flex-direction:column">
            <img src="img/FriendsPlus/Page28/E1/Images/1.png"  style="margin-right:30px"/>
              <u style="font-weight:bold; color: gray">&ensp;natural&ensp;</u>
            </div>
        
            <div style="display:flex;flex-direction:column">
            <img src="img/FriendsPlus/Page28/E1/Images/2.png"   />
              <span >1 #</span>
            </div>
            </div>

 
            <div style="display:flex;flex-direction:row;margin-top:40px">
            <div style="display:flex;flex-direction:column">
            <img src="img/FriendsPlus/Page28/E1/Images/3.png"  style="margin-right:30px;width:430px"/>
              <span >2 &emsp;  #</span>
            </div>
        
            <div style="display:flex;flex-direction:column">
            <img src="img/FriendsPlus/Page28/E1/Images/4.png" style="width:430px;height:280px" />
              <span >3&emsp; #</span>
            </div>
            </div>



            <div style="display:flex;flex-direction:row;margin-top:40px">
            <div style="display:flex;flex-direction:column">
            <img src="img/FriendsPlus/Page28/E1/Images/5.png"  style="margin-right:30px;width:430px"/>
              <span>4 &emsp; #</span>
            </div>
        
            <div style="display:flex;flex-direction:column">
            <img src="img/FriendsPlus/Page28/E1/Images/6.png"  style="width:430px;height:275px"/>
              <span >5 &emsp; #</span>
            </div>
            </div>


            

            <div style="display:flex;flex-direction:row;margin-top:40px">
            <div style="display:flex;flex-direction:column">
            <img src="img/FriendsPlus/Page28/E1/Images/7.png"  style="margin-right:30px;width:430px"/>
              <span >6 &emsp;#</span>
            </div>
        
            <div style="display:flex;flex-direction:column">
            <img src="img/FriendsPlus/Page28/E1/Images/8.png" style="width:430px;height:275px" />
              <span >7&emsp; #</span>
            </div>
            </div>
            
            
            </div>

        
            
            
          
					</div>
          </div>
        `,
        answer: [
          `second-hand`,
          `automatic`,
          `home-made`,
          `multi-functional `,
          `waterproof`,
          `solar`,
          `smart`,
        ],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P28-E8",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page28/E2/Key/E2answerkey.png",
    component: T6v2,
    inputSize: 160,
    maxLength: 16,
    stylesTextInput: {
      borderStyle: "2px dotted",
      fontSize: "22px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "2",
        title: `
				Complete the advert with the words.
				`,
        color: "rgb(246,127,34)",
        star: 2,
      },
    ],

    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div>
          

          <div style='width: 800px; display: flex; flex-direction: column; justify-content: center; align-items: center; font-size: 22px; line-height: 45px'>
            <div style='display: flex; flex-direction:column;flex-wrap: wrap; gap: 10px; align-items: center; justify-content: center;
              max-width: 900px; padding: 10px 80px; border-radius: 20px; border: 3px solid rgb(246,127,34);'>
             
              <div>
              <span style="text-decoration: line-through;">digital</span>&emsp; <span>ecological &emsp; electric &emsp; electronic</span> 
              </div>

              <div>
               &emsp; <span>natural &emsp;  recycled &emsp; solar </span>
              </div>
           
            
             
            </div>

            <div style="width:600px;margin-top:20px" >
            In today’s <span style="font-weight:bold">digital </span>  world, our homes are full 
of many (1) # gadgets. But hi-tech isn’t 
always the answer, is it? We also want to reduce 
waste and save the planet. In our kitchens, we want 
(2) # food products, and we carry them 
home in (3) # bags. But what about 
the equipment we use? Well, in Bolivia there is 
an alternative to the (4) # cooker. The (5) # cooker doesn’t need electricity. 
Instead, it uses the sun’s heat to cook food. It saves 
energy, so it’s an (6)# product. The heat 
goes through a glass panel and temperatures get up 
to 160ºC!


         
        
            
            </div>

        
            
            
          
					</div>
          </div>
        `,
        answer: [
          `electronic`,
          `natural`,
          `recycled`,
          `electric`,
          `solar`,
          `ecological`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P28-E8",
    audio: "Audios/1-09 Friends Plus 8_WB.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page28/E3/Key/E3answerkey.png",
    component: T6v2,
    inputSize: 120,
    maxLength: 7,
    stylesTextInput: {
      borderStyle: "dotted",
      fontSize: "24px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "3",
        title: `
        <headphone name='09' src='/Audios/1-09 Friends Plus 8_WB.mp3'></headphone>&ensp;  Listen to some product reviews 
        at the Gadget Show. Write <i>fact</i> or <i>opinion</i> next to the things they mention.
				`,
        color: "rgb(246,127,34)",
        star: 2,
      },
    ],
    textareaStyle: {
      width: 400,
    },
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <span>It’s great for lazy people. <u style="font-weight:bold;font-style:italic; color: gray">&ensp;opinion&ensp;</u></span>
					<div style="display:flex;flex-direction:column; line-height: 50px">
             <span><strong style="margin-right:30px">1</strong>It’s multi-functional, so you can listen to music, 
            watch films and more. #</span>
            <span><strong style="margin-right:30px">2</strong>It fits in your pocket.  #</span>
            <span><strong style="margin-right:30px">3</strong>You don’t need to worry about running out of 
            power.  #</span>
            <span><strong style="margin-right:30px">4</strong>It’s perfect for my dad.  #</span>
            <span><strong style="margin-right:30px">5</strong>It’s exciting, but quite expensive.  #</span>
            <span><strong style="margin-right:30px">6</strong>It makes you look like a superhero.  #</span>
  
          </div>
        `,
        answer: [`fact`, `fact`, `fact`, `opinion`, `opinion`, `opinion`],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P28-E8",
    audio: "Audios/1-09 Friends Plus 8_WB.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page28/E4/Key/E4answerkey.png",
    component: T6v2,
    inputSize: 200,
    maxLength: 10,
    stylesTextInput: {
      borderStyle: "dotted",
      fontSize: "24px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
        <headphone name='09' src='/Audios/1-09 Friends Plus 8_WB.mp3'></headphone> &ensp; 
				Listen again and write <i>true, false</i> or <i>don't know</i> if they don’t mention it.
				`,
        color: "rgb(246,127,34)",
        star: 2,
      },
    ],
    textareaStyle: {
      width: 400,
    },
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
       
					<div style="display:flex;flex-direction:column; line-height: 50px">
             <span><strong style="margin-right:30px">1</strong>The presenter is looking for the craziest 
gadgets on offer #</span>
  <span><strong style="margin-right:30px">2</strong>The dog collars tell you if your dog is getting 
enough exercise.  #</span>
  <span><strong style="margin-right:30px">3</strong>The dog collars tell you if your dog is getting 
enough exercise. #</span>
  <span><strong style="margin-right:30px">4</strong>The Eco Media Player is made from recycled 
materials  #</span>
  <span><strong style="margin-right:30px">5</strong>The Eco Media Player doesn’t use electricity.   #</span>
  <span><strong style="margin-right:30px">6</strong>You don’t need a smartphone to use the 
Automatic Parked Car Finder #</span>
 <span><strong style="margin-right:30px">7</strong>You can use the Logbar Ring to open and close 
doors in your house.  #</span>
  

          

       
          </div>
        `,
        answer: [
          `false`,
          `true`,
          `don't know`,
          `don't know`,
          `true`,
          `false`,
          `don't know`,
        ],
      },
    ],
  },
  5: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P28-E8",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page28/E5/Key/E5answerkey.png",
    component: T6v2,
    inputSize: 120,
    stylesTextInput: {
      borderStyle: "dotted",
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "5",
        title: `
        <headphone1 name="0.9"></headphone1>
				 Imagine you are making a new 
household gadget. Which words in exercises 1 and 2 
could help describe your product as … ?
				`,
        color: "rgb(246,127,34)",
        star: 2,
      },
    ],
    textareaStyle: {
      width: 400,
    },
    inputSize: 600,
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
       
					<div style="display:flex;flex-direction:column">
             <span><strong style="margin-right:30px">A</strong>useful or quick to use </span>
             <span style="margin-left:35px">#</span>
  <span><strong style="margin-right:30px">B</strong>good for the environment  </span><span style="margin-left:35px">#</span>
  <span><strong style="margin-right:30px">C</strong>exciting and modern </span> <span style="margin-left:35px">#</span>
 
  

          

       
          </div>
        `,
        answer: [
          `automatic, multi-functional, smart, waterproof`,
          `ecological, recycled, solar`,
          `digital, electric, electronic, high-tech, smart`,
        ],
      },
    ],
  },

  6: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P28-E8",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page28/E2/Key/E2answerkey.png",
    component: T6v2,
    hideBtnFooter: true,
    inputSize: 120,
    stylesTextInput: {
      borderStyle: "dotted",
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "6",
        title: `
          <headphone1 name="0.9"></headphone1>
          Write a short advert for your gadget. 
          Use the advert in exercise 2 to help you. Use facts 
          and opinions to promote it.
          `,
        color: "rgb(246,127,34)",
        star: 3,
      },
    ],
    textareaStyle: {
      width: 1100,
    },
    inputSize: 450,
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
           <span style="font-weight:bold;font-style:italic">In today’s busy world, people want products that are quick
           and simple to use. This new and exciting kitchen gadget is
           automatic and multi-functional ...</span>
            <div style="display:flex;flex-direction:column">
           <textarea id="0" rows="7"></textarea>
   
    
  
            
  
         
            </div>
          `,
        answer: [
          `automatic, multi-functional, smart, waterproof`,
          `ecological, recycled, solar`,
          `digital, electric, electronic, high-tech, smart`,
        ],
      },
    ],
  },
};

export default json;
