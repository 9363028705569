import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 5",
    id: "FP8-WB-demo-2023-U5-P65-E1",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page65/Key/E1answerKey.png",
    component: T6v2,
    textareaStyle: { width: "730px" },
    // maxLength: 6,
    inputSize: 150,
    isHiddenCheck: true,
    checkUppercase: true,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      //   borderBottom: '1px solid black',
      textAlign: "center",
      fontSize: "22px",
      // fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "1",

        title: `
					
        Complete the first conditional sentences with the correct <br> form of the verbs in brackets and <i>if</i> or <i>unless</i>.
											`,
        color: "#4d3c98",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
					
				<div style='line-height:50px; font-size: 22px'>
        I <u style='color: gray'>&ensp;I’ll come&ensp;</u> (come) out later <b><u style='color: gray'>&ensp;unless&ensp;</u></b> I’m too tired.

        <div><b>1.</b>&ensp; # it # (rain) tomorrow, we’ll stay inside and watch a film. </div>

        <div><b>2.</b>&ensp; We # (text) you # we have any problems. </div>

        <div><b>3.</b>&ensp; # they’re too heavy, I # (carry) the bags. </div>

        <div><b>4.</b>&ensp; She won’t understand you # you # (speak) clearly. </div>

        <div><b>5.</b>&ensp; #  she wins the race, she # (be) so happy. </div>
        
					
				</div>
						
											`,
        answer: [
          "If",
          "rains",
          `'ll text`,
          "if",
          "If",
          `'ll carry`,
          "unless",
          "speak",
          "If",
          `'ll be`,
        ],
      },
    ],
  },
  2: {
    unit: "Unit 5",
    id: "FP8-WB-demo-2023-U5-P65-E2",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page65/Key/E2answerKey.png",
    component: T6v2,
    maxLength: 18,
    inputSize: 150,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      textAlign: "center",
      fontSize: "22px",
      // fontWeight: "bold",
      borderBottom: "2px dotted",
    },
    titleQuestion: [
      {
        num: "2",

        title: `
        Complete the dialogue with <i>will, won’t, might</i> or <i>might not</i>.
											`,
        color: "#4d3c98",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
    <div style='line-height: 50px; font-size: 22px; width: 900px'>
        <div style='display:flex'> <b>Zac</b> <span style='margin-left:20px'> <b><u style='color: gray'>&ensp;Will&ensp;</u></b> you see Aisha tonight at the café after swimming club?</span></div>

        <div style='display:flex'> <b>Eli</b> <span style='margin-left:20px'>  I <sup>1</sup> # do. I hope so. She <sup>2</sup> # be at swimming because she never misses it. But she <sup>3</sup> # come to the café afterwards. Sometimes, she goes home early.</span></div>

        <div style='display:flex'> <b>Zac</b> <span style='margin-left:20px'> OK, well, if you see her, can you tell her that I <sup>4</sup> # be late for band practice on Thursday? I <sup>5</sup> # be in town all day on a school trip, and it <sup>6</sup> # finish after five o’clock. I can’t be sure.</span></div>

        <div style='display:flex'> <b>Eli</b> <span style='margin-left:20px'>No problem. I <sup>7</sup> # tell her.</span></div>

        <div style='display:flex'> <b>Zac</b> <span style='margin-left:20px'>You <sup>8</sup> # forget, will you?</span></div>

        <div style='display:flex'> <b>Eli</b> <span style='margin-left:20px'>Of course not!</span></div>

    </div>
		
	
			
								`,
        answer: [
          `might`,
          `'ll`,
          `might not`,
          `might`,
          `'ll`,
          `might`,
          `'ll`,
          `won't`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 5",
    id: "FP8-WB-demo-2023-U5-P65-E3",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page65/Key/E3answerKey.png",
    component: Circle_Write,
    // maxLength: 6,
    // inputSize: 150,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      textAlign: "center",
      fontSize: "20px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "3",

        title: `
					
        Choose the correct words.
											`,
        color: "#4d3c98",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 100, fontSize: 19, textAlign: "center" },
        answers: [""],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 2px",
          // borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [
          `’ll / ’m_going_to`, //1
          `’ll / ’re_going_to`, //1
          `’ll / ’re_going_to`, //1
          `’ll / ’s_going_to`, //1
        ],
        answers: ["0-4", "1-0", "2-4", "3-0"],
        initialValue: [],
      },
      Layout: `
      
        
      
        <div style='line-height:50px'>
        What <span><b>will /  <span style='padding: 5px; border:2px solid black;border-radius:50%'>are you going to</span></b></span> do for your birthday?
          <div><b>1</b>&ensp; I <input id='0' type='Circle'/> study art at university.</div>

          <div><b>2</b>&ensp; Do you think they <input id='1' type='Circle'/> enjoy the film?</div>
          
          <div><b>3</b>&ensp; We <input id='2' type='Circle'/> fly to Paris. It’s cheaper than the train.</div>
          
          <div><b>4</b>&ensp; ‘Dad’s out late tonight.’ ‘He<input id='3' type='Circle'/> be home soon. </div>

    
        </div>
      `,
    },
  },
  4: {
    unit: "Unit 5",
    id: "FP8-WB-demo-2023-U5-P65-E4",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page65/Key/E4answerKey.png",
    component: T6v2,
    maxLength: 30,
    inputSize: 250,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      textAlign: "center",
      fontSize: "22px",
      // fontWeight: "bold",
      borderBottom: "2px dotted",
    },
    hintBox: [
      {
        src: [`̶d̶̶o̶`, "listen", "make", `not watch`, "not work", "play", "talk"],
        borderColor: "#4d3c98",
        width: 700,
      },
    ],
    titleQuestion: [
      {
        num: "4",

        title: `
					
        Complete the sentences using the correct future 
        continuous form of the verbs in the box.

											`,
        color: "#4d3c98",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <hintBox id='0'></hintBox>
		<div style="line-height:50px; font-size: 22px">
        <div>
        At seven o’clock tomorrow evening …<br>
        I <b><u style='color: gray'>&ensp;’ll be doing&ensp;</u></b> my homework.    
        </div>
        <div><b>1.</b>&ensp; My sister # on the phone.</div>

        <div><b>2.</b>&ensp; My dad # dinner.</div>

        <div><b>3.</b>&ensp; My mum # at the hospital because it’s her night off.</div>

        <div><b>4.</b>&ensp; I # to music on the radio.</div>

        <div><b>5.</b>&ensp; My friends # games.</div>

        <div><b>6.</b>&ensp; My brothers # TV.</div>
    
    </div>				
											`,
        answer: [
          `will be talking`,
          `will be making`,
          `won't be working`,
          `'ll be listening`,
          `will be playing`,
          `won't be watching`,
        ],
      },
    ],
  },
  5: {
    unit: "Unit 5",
    id: "FP8-WB-demo-2023-U5-P65-E5",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page65/Key/E5answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 850,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      //   textAlign: 'center',
      fontSize: "22px",
      // fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "5",

        title: `
					
        Combine two sentences using <i>to</i>.
											`,
        color: "#4d3c98",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='line-height:50px; font-size: 22px'>
          <div>
          <b>1.</b>&ensp; Jimmy studied really hard this term. He wanted to get better marks. <br>
          #.
          </div>      
        
          <div>
          <b>2.</b>&ensp; Most young people leave their hometown. They want to seek for opportunities in big cities. <br>
          #.
          </div>

          <div>
          <b>3.</b>&ensp; You must take your raincoat this season. You can avoid getting a cold. <br>
          #.
          </div>
        
          <div>
          <b>4.</b>&ensp; I try all my best to study Japanese. I hope I can find a better job. <br>
          #.
          </div>

          <div>
          <b>5.</b>&ensp; Mrs Jenny turned off the radio. She wanted to take a nap.<br>
          #.
          </div>
        </div>
								`,
        answer: [
          `Jimmy studied really hard this term to get better marks`,
          `Most young people leave their hometown to seek for opportunities in big cities`,
          `You must take your raincoat this season to avoid getting a cold`,
          `I try all my best to study Japanese to find a better job`,
          `Mrs Jenny turned off the radio to take a nap`,
        ],
      },
    ],
  },
  6: {
    unit: "Unit 5",
    id: "FP8-WB-demo-2023-U5-P65-E6",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6v2,
    // maxLength: 1,
    inputSize: 500,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "6",
        title: `
        Follow the steps in the Writing Guide.

        `,
        color: "#4d3c98",
        prefix: { icons: ["fas fa-pencil-alt"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        
        <div style="margin-left: 100px"><img src="img/FriendsPlus/Page65/14.jpg" /></div>
        

        `,
        answer: [],
      },
    ],
  },
};

export default json;
