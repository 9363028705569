import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import TB2 from "../../components/ExcerciseTypes/Table/TB2";

const json = {
  1: {
    unit: "Unit 3",
    id: "FP8-SB-demo-2023-U3-P30-E1",
    audio: "Audios/1-10 Friends Plus 8_WB.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page30/E1/Key/E1answerkey.png",
    component: T6v2,
    inputSize: 60,
    isHiddenCheck: true,

    titleQuestion: [
      {
        num: "1",
        title: `
        <headphone name='10' src='/Audios/1-10 Friends Plus 8_WB.mp3'></headphone>&ensp; <span style='font-size: 24px'>Read and listen to the web article. Match paragraphs A–D with headings 1–5. There is one extra heading.</span>
				`,
        color: "rgb(220,44,55)",
        star: 1,
      },
    ],
    stylesTextInput: {
      background: "none",
      textAlign: "center",
      // borderBottom: "none",
      fontSize: 22,
      height: 27,
      // textTransform: 'uppercase',
      // borderStyle: "dotted",
    },
    questions: [
      {
        title: `
					<div style='display: flex;flex-direction:column; font-size: 22px'>
 

          <div style="display: flex;flex-direction:row">
          
          <img src="img/FriendsPlus/Page30/E1/1.png"  style="width:11cm"/>
          <img src="img/FriendsPlus/Page30/E1/2.png" style="width:11cm; height: 11cm; margin-top: 198px "/>

          </div>
          <div style='display: flex'>
            <div style='display: flex;flex-direction: column;' >
              <span><strong style="margin-right:30px">1</strong> A popular interest &ensp;  <strong style="font-style:italic">A</strong></span>
              <span><strong style="margin-right:30px">2</strong> Cooking and eating together</span>
              <span><strong style="margin-right:30px">3</strong> Using less energy</span>
              <span><strong style="margin-right:30px">4</strong> Making our own food</span>
              <span><strong style="margin-right:30px">5</strong> Making cooking easier</span>
            </div>
            <div style='margin-left: 50px'>
              <b>Answer:</b><br>
              <div style='display:flex; flex-direction: column; '>
              &ensp;<b style='color: gray'>A - 1</b>
              &ensp;<b>B - #</b>
              &ensp;<b>C - #</b>
              &ensp;<b>D - #</b>
              </div>
            </div>
          </div>
          


					</div>	
				`,
        answer: [`5`, `2`, `4`],
      },
    ],
  },
  2: {
    unit: "Unit 3",
    id: "FP8-SB-demo-2023-U3-P30-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page30/E2/Key/E2answerkey.png",
    component: T6v2,
    maxLength: 5,
    // isHiddenCheck: true,
    titleQuestion: [
      {
        num: "2",
        title: `
				Read the article again and write <i>true</i> or <i>false</i>.
				`,
        color: "rgb(220,44,55)",
        star: 2,
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      fontSize: 24,
      height: 27,
      // textTransform: 'uppercase',
      // borderStyle: "dotted",
      textAlign: "center",
    },
    inputSize: 100,
    questions: [
      {
        title: `
        <span>Today, many people enjoy cooking  <strong style="text-decoration:underline; color: gray">&ensp;true&ensp;</strong></span>
					<div style='display: flex;flex-direction:column; line-height: 60px'>
 

         <span><strong style="margin-right:30px">1</strong>Cooks today don’t have help from technology #</span>
         <span><strong style="margin-right:30px">2</strong>In the future, ovens will make our diets 
         healthier.  #</span>
         <span><strong style="margin-right:30px">3</strong>In the future, fridges will do the shopping for 
         us. #</span>
         <span><strong style="margin-right:30px">4</strong>We’ll learn from chefs at home. #</span>
         <span><strong style="margin-right:30px">5</strong>People won’t need to be together to share 
         meals. #</span>

					</div>	
				`,
        answer: [`false`, `true`, `false`, `true`, `true`],
      },
    ],
    tb2Style: { width: 400 },
    type: 2, // Dạng 2 thì nhập số 2
    data: [
      {
        title: "Land",
        content: ["#", "#", "#", "#", "#", "#"],
        answers: [`valley`, `cave`, `rainforest`, `beaches`, `dunes`, `desert`],
        commonStyle: {},
        titleStyle: {
          backgroundColor: "#7FA8D9",
          border: "1px solid #468DCB",
          color: "#fff",
        },
        contentStyle: {
          backgroundColor: "#CDDBF0",
          border: "1px solid #468DCB",
          color: "#58585a",
        },
      },
      {
        title: "Water",
        content: ["see", "#", "#", "#", "#"],
        answers: [``, `river`, `falls`, `waves`, `ocean`],
        commonStyle: {},
        titleStyle: {
          backgroundColor: "#7FA8D9",
          border: "1px solid #468DCB",
          color: "#fff",
        },
        contentStyle: {
          backgroundColor: "#CDDBF0",
          border: "1px solid #468DCB",
          color: "#58585a",
        },
      },
    ],
  },
  3: {
    unit: "Unit 3",
    id: "FP8-SB-demo-2023-U3-P30-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page30/E3/Key/E3answerkey.png",
    component: T6v2,
    // maxLength: 5,
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "3",
        title: `
				What do the bold words in the article refer to?
				`,
        color: "rgb(220,44,55)",
        star: 2,
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      fontSize: 23,
      height: 29,
      // textTransform: 'uppercase',
      // borderStyle: "dotted",
      textAlign: "center",
    },
    inputSize: 450,
    questions: [
      {
        title: `
					<div style='display: flex;flex-direction:column; line-height: 60px'>

          <span>they &ensp;<u><strong style="font-style:italic; color: gray">&ensp;&ensp;&ensp;ovens&ensp;&ensp;&ensp;</strong></u></span>

          <span><strong style="margin-right:30px">1</strong> it&ensp;&ensp;&ensp;&ensp;#</span>
          <span><strong style="margin-right:30px">2</strong> them  #</span>

          <span><strong style="margin-right:30px">3</strong> it&ensp;&ensp;&ensp;&ensp; #</span>
          <span><strong style="margin-right:30px">4</strong> they&ensp; #</span>
          <span><strong style="margin-right:30px">5</strong> that&ensp;  #</span>


					</div>	
				`,
        answer: [
          `the food in our fridges`,
          `recipes`,
          `the meal`,
          `dinner guests`,
          `the food we'll grow at home`,
        ],
      },
    ],
    tb2Style: { width: 400 },
    type: 2, // Dạng 2 thì nhập số 2
    data: [
      {
        title: "Land",
        content: ["#", "#", "#", "#", "#", "#"],
        answers: [`valley`, `cave`, `rainforest`, `beaches`, `dunes`, `desert`],
        commonStyle: {},
        titleStyle: {
          backgroundColor: "#7FA8D9",
          border: "1px solid #468DCB",
          color: "#fff",
        },
        contentStyle: {
          backgroundColor: "#CDDBF0",
          border: "1px solid #468DCB",
          color: "#58585a",
        },
      },
      {
        title: "Water",
        content: ["see", "#", "#", "#", "#"],
        answers: [``, `river`, `falls`, `waves`, `ocean`],
        commonStyle: {},
        titleStyle: {
          backgroundColor: "#7FA8D9",
          border: "1px solid #468DCB",
          color: "#fff",
        },
        contentStyle: {
          backgroundColor: "#CDDBF0",
          border: "1px solid #468DCB",
          color: "#58585a",
        },
      },
    ],
  },
  4: {
    unit: "Unit 3",
    id: "FP8-SB-demo-2023-U3-P30-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page30/E4/E4answerkey.png",
    component: T6v2,
    checkUppercase: true,
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "4",
        title: `
        Answer the questions. Write complete 
        sentences.
				`,
        color: "rgb(220,44,55)",
        star: 3,
      },
    ],
    textareaStyle: {
      width: 800,
      height: 100,
      fontSize: 23,
    },
    inputSize: 650,
    questions: [
      {
        title: `
					<div style='display: flex;flex-direction:column; font-size: 23px'>
 


          <span>How do the kitechen gadgets we have today help us ?</span>

          <strong style="font-style:italic">They help us cook in a quick, easy and eco-friendly way.</strong>

         <div style="display: flex;flex-direction:row">
         <strong style="margin-right:30px">1 </strong>
         <div style="display: flex;flex-direction:column">
         <span>What do experts think will happen to the 
         number of gadgets in kitchens?</span>
         <textarea id="0" rows=1></textarea>
         </div>
         </div>

         <div style="display: flex;flex-direction:row">
         <strong style="margin-right:30px">2 </strong>
         <div style="display: flex;flex-direction:column">
         <span>How will food shopping change?</span>
         <textarea id="1" rows=2></textarea>
         </div>
         </div>


         <div style="display: flex;flex-direction:row">
         <strong style="margin-right:30px">3 </strong>
         <div style="display: flex;flex-direction:column">
         <span>What will chef holograms do?</span>
         <textarea id="2" rows=2></textarea>
         </div>
         </div>

         <div style="display: flex;flex-direction:row">
         <strong style="margin-right:30px">4 </strong>
         <div style="display: flex;flex-direction:column">
         <span>Where will our food come from?</span>
         <textarea id="3" rows=2></textarea>
         </div>
         </div>


					</div>	
				`,
        answer: [
          `It will increase in the future.`,
          `Our fridges will send us shopping lists to tell us which foods we need to buy.`,
          `They'll teach us how to cook meals through virtual reality.`,
          `We'll grow our food at home or use 3D printers to reproduce it.`,
        ],
      },
    ],
    tb2Style: { width: 400 },
    type: 2, // Dạng 2 thì nhập số 2
    data: [
      {
        title: "Land",
        content: ["#", "#", "#", "#", "#", "#"],
        answers: [`valley`, `cave`, `rainforest`, `beaches`, `dunes`, `desert`],
        commonStyle: {},
        titleStyle: {
          backgroundColor: "#7FA8D9",
          border: "1px solid #468DCB",
          color: "#fff",
        },
        contentStyle: {
          backgroundColor: "#CDDBF0",
          border: "1px solid #468DCB",
          color: "#58585a",
        },
      },
      {
        title: "Water",
        content: ["see", "#", "#", "#", "#"],
        answers: [``, `river`, `falls`, `waves`, `ocean`],
        commonStyle: {},
        titleStyle: {
          backgroundColor: "#7FA8D9",
          border: "1px solid #468DCB",
          color: "#fff",
        },
        contentStyle: {
          backgroundColor: "#CDDBF0",
          border: "1px solid #468DCB",
          color: "#58585a",
        },
      },
    ],
  },
  5: {
    unit: "Unit 3",
    id: "FP8-SB-demo-2023-U3-P30-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page30/E5/Key/E5answerkey.png",
    component: T6v2,
    isHiddenCheck: true,
    maxLength: 8,
    titleQuestion: [
      {
        num: "5",
        title: `
       <strong >VOCABULARY PLUS </strong> Complete the 
       sentences with the words.
				`,
        color: "rgb(220,44,55)",
        star: 2,
      },
    ],

    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      fontSize: 23,
      height: 27,
      // textTransform: 'uppercase',
      // borderStyle: "dotted",
      textAlign: "center",
    },
    inputSize: 200,
    questions: [
      {
        title: `
					<div style='display: flex;flex-direction:column; line-height: 60px; font-size: 23px; margin-top: 30px'>

          <div style='display: flex; flex-direction:column;flex-wrap: wrap; gap: 10px; align-items: center; justify-content: center;
					 width:800px;padding: 10px 80px;border-radius: 20px; border: 3px solid rgb(220,44,55);'>
				   
				
					<div>
          billion hundred million <span style="text-decoration:line-through">percent</span> thousand
					</div>

          </div>

          <span>About eleven <strong style="font-style:italic;text-decoration:underline;">percent</strong> of the people in the 
          world haven’t got enough food to eat.</span>



          <span> <strong style="margin-right:30px">1</strong>There are more people in China, at around 1.4 
          #,  than all of the countries in Africa. </span>

          <span> <strong style="margin-right:30px">2</strong>Human beings can live to around one # 
          and fifteen years. </span>

          <span> <strong style="margin-right:30px">3</strong>It’s around one # kilometres from 
          London to Berlin. </span>

          <span> <strong style="margin-right:30px">4</strong>TAbout 749 # people live in Europe.  </span>


					</div>	

          
         
				`,
        answer: [`billion`, `hundred`, `thousand`, `million`],
      },
    ],
    tb2Style: { width: 400 },
    type: 2, // Dạng 2 thì nhập số 2
    data: [
      {
        title: "Land",
        content: ["#", "#", "#", "#", "#", "#"],
        answers: [`valley`, `cave`, `rainforest`, `beaches`, `dunes`, `desert`],
        commonStyle: {},
        titleStyle: {
          backgroundColor: "#7FA8D9",
          border: "1px solid #468DCB",
          color: "#fff",
        },
        contentStyle: {
          backgroundColor: "#CDDBF0",
          border: "1px solid #468DCB",
          color: "#58585a",
        },
      },
      {
        title: "Water",
        content: ["see", "#", "#", "#", "#"],
        answers: [``, `river`, `falls`, `waves`, `ocean`],
        commonStyle: {},
        titleStyle: {
          backgroundColor: "#7FA8D9",
          border: "1px solid #468DCB",
          color: "#fff",
        },
        contentStyle: {
          backgroundColor: "#CDDBF0",
          border: "1px solid #468DCB",
          color: "#58585a",
        },
      },
    ],
  },
};

export default json;
