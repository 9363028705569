import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P59-E1",
    audio: "",
    video: "",
    recorder: true,
    exerciseKey: "",
    component: T6v2,
    // maxLength: 1,
    inputSize: 280,
    textareaStyle: {
      // background: 'none',
      width: 700,
      fontSize: 22,
      height: 130,
    },
    titleQuestion: [
      {
        num: "1",

        title: `
        <span style='color:#594b94' ><b>SPEAKING</b></span> Look at the photo. What are the people doing?
												`,
        color: "#594b94",
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
			<div style='margin-left:150px'><img src="img/FriendsPlus/Page59/E1/1.jpg"/></div>
			<div style='margin-left: 30px'><textarea id='1' rows='3'></div>
						
												`,
        answer: [],
      },
    ],
  },
  2: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P59-E2",
    audio: "",
    // hideBtnFooter:true,
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page59/Key/E2answerKey.png",
    component: T6v2,
    // maxLength: 1,
    inputSize: 280,
    textareaStyle: { width: "530px", height: 150 },
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "22px",
      // fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "2",

        title: `
        <span style='color:#594b94' ><b>Reading!</b></span> Read a discussion essay. Why do many people prefer watching films to reading books?
												`,
        color: "#594b94",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
			<div style='display:flex'>
      <img src="img/FriendsPlus/Page59/E2/1.jpg" />
      <div style='margin-left:15px'>
      <u><i>Answer:</i></u>
      <textarea id='0' rows='1'></textarea>
      </div>
      </div>
			
						
												`,
        answer: [`Because you can do it with family and friends.`],
      },
    ],
  },
  3: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P59-E5",
    // audio: 'Audios/1-22 Friends Plus 8_WB.mp3',

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page59/Key/E3answerKey.png",
    component: T6v2,
    maxLength: 105,
    inputSize: 950,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "left",
      fontSize: "22px",
      // fontWeight: "bold",
      borderBottom: "2px dotted",
    },
    titleQuestion: [
      {
        num: "3",

        title: `
        Read the discussion essay again. Correct one word in each sentence.
		`,
        color: "#594b94",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
      <div style='line-height:50px; font-size: 22px'>
        <div style='display:flex'><b>1.</b>&ensp; Watching films is terrible because you do it with friends.</div>
        #.
        <div style='display:flex'><b>2.</b>&ensp; When you read a book, you may feel like you’re in a different film.</div> 
        #.
        <div style='display:flex'><b>3.</b>&ensp; The author of the essay can see different awards of reading books and watching films.</div> 
        #.
        <div style='display:flex'><b>4.</b>&ensp;  Books and films aim to ban entertainment.</div>
        #.


      </div>
        `,
        answer: [
          `Watching films is amazing because you do it with friends`,
          `When you read a book, you may feel like you're in a different world`,
          `The author of the essay can see different benefits of reading books and watching films`,
          `Books and films aim to provide entertainment`,
        ],
      },
    ],
  },
  4: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P59-E4",
    audio: "Audios/1-24 Friends Plus 8_WB.mp3",
    video: "",
    component: T6v2,
    exerciseKey: "img/FriendsPlus/Page59/Key/E4answerKey.png",
    stylesTextInput: { borderBottom: "2px dotted", fontSize: 23 },
    inputSize: 400,
    // maxLength: 3,
    isHiddenCheck: true,
    // checkUppercase: true,
    // exerciseKey: "img/FriendsPlus/Page32/E8/Key/answerKey.png",
    titleQuestion: [
      {
        num: "4",
        title:
          '<headphone name="24"></headphone >&ensp; <span>Listen to Joe talking to a librarian. What type of films does Joe like?</span>',

        color: "#594b94",
      },
    ],
    questions: [
      {
        title: `
       <div style='display:flex'>
       <img src="img/FriendsPlus/Page59/E4/1.jpg" />
       <div style='margin-left:15px'>
        <i><u>Answer:</u></i> <br>
        #
       </div>
       </div>
			
						
												`,
        answer: [`adventure, science fiction`],
      },
    ],
  },
  5: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P59-E5",
    audio: "Audios/1-24 Friends Plus 8_WB.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page59/Key/E5answerKey.png",
    component: T6v2,
    maxLength: 15,
    inputSize: 140,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "23px",
      // fontWeight: "bold",
      borderBottom: "2px dotted",
    },
    titleQuestion: [
      {
        num: "5",

        title: `
        <headphone name="24"></headphone >&ensp; Listen again and complete the sentences.
		`,
        color: "#594b94",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
      <div style='line-height:50px; font-size: 23px'>
        <div style='display:flex; align-items: center;'><b>1.</b>&ensp; Joe’s # teacher has given them special homework.</div>
        <div style='display:flex; align-items: center;'><b>2.</b>&ensp; Joe thinks # stories aren’t interesting.</div> 
        <div style='display:flex; align-items: center;'><b>3.</b>&ensp; Joe sometimes watches all the films that were made by the same #.</div> 
        <div style='display:flex; align-items: center;'><b>4.</b>&ensp;  The librarian thinks Joe might like # novels.</div> 


      </div>
        `,
        answer: [`English`, `classic`, `director`, `graphic`],
      },
    ],
  },
  6: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P59-E6",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page56/Key/E6answerKey.png",
    component: T6v2,
    // maxLength: 1,
    inputSize: 150,
    textareaStyle: { width: "500px" },
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "20px",
      textAlign: "center",
      fontWeight: "bold",
      // border:'dotted',
    },
    titleQuestion: [
      {
        num: "6",
        title: `
        A TASK Write a discussion essay with the title Film first or book first? Discuss.
        `,
        color: "#6A5EA1",
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
      <div style='line-height:2'>
        <div>
        <b>B THINK AND PLAN</b>
        <div><b>1</b>&ensp; Introduce the topic.</div>
        <div><b>2</b>&ensp; Why is it better to read the book first? Present the arguments.</div>
        <div><b>3</b>&ensp; Why is it better to watch the film first? Present the arguments.</div>
        <div><b>4</b>&ensp; In your opinion, which is better: watching the film first or reading the book first?</div>
        </div>

        <div>
        <b>C WRITE Use the text in exercise 2 and the writing guide.</b>
        <div style='display:flex  '>
        <img src="img/FriendsPlus/Page59/E6/1.jpg" style='width:45%' />
          <div style='margin-left:15px'>
          <i>ANSWER:</i> 
          <textarea id='0' rows='5'></textarea></div>
        </div>
          
        </div>
      
  
  


      </div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
