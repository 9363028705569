import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";

const json = {
  1: {
    unit: "Unit 7",
    id: "FP8-WB-demo-2023-U7-P44-E1",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "/img/FriendsPlus/Page44/Key/E1answerKey.png",
    component: T6v2,
    maxLength: 1,
    inputSize: 37,
    isHiddenCheck: true,
    stylesTextInput: {
      background: "transparent",
      border: "none",
      textAlign: "center",
      fontSize: "19px",
      textTransform: "uppercase",
      fontWeight: 600,
    },
    titleQuestion: [
      {
        num: "1",

        title: `
        Complete the crossword with the verbs. 
        Use the clues to find the words with a similar meaning.
					`,
        color: "rgb(37,105,180)",
        star: 1,
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    // isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style="display:flex">
        <div>
        <div style='margin-top: 20px; display: flex; gap: 30px;max-width:500px;margin-right:20px'>
        <div style='flex: 1; border-radius: 20px; padding: 20px 30px; border: 3px solid rgb(37,105,180);'>
          <div><span style="margin-right:20px">aim</span><span style="margin-right:20px">ban</span><span style="margin-right:20px">believe</span><span style="margin-right:20px">boycott</span><span>campaign</span></div>
          <div><span style="margin-right:20px; margin-left:45px">donate</span><span style="margin-right:20px">end</span><span style="margin-right:20px">propose</span><span>protest</span></div>
          <div><span style="margin-right:20px; margin-left:80px">sign</span><span style="margin-right:20px">support</span><span>volunteer</span></div>
        </div>
        
      </div>
      
      <div style="display:flex;margin-left:10px;margin-top:20px">
      <div style="margin-right:80px">
      <b>1</b> avoid  <br>
      <b>2</b> give <br>
      <b>3</b> fight  <br>
      <b>4</b> get rid of <br>
      <b>5</b> plan <br>
      <b>6</b> write your name <br>
      
      </div>
      <div>
      <b>7 </b> object to<br>
      <b>8 </b> stop<br>
      <b>9 </b> think<br>
      <b>10</b>  recommend<br>
      <b>11</b>  help without pay<br>
      <b>12</b>  agree with<br>
      </div>
      </div>
      </div>

      <div>
          
          <div style='position: relative;'>
							<div style='display:flex'>
              <img src="/img/FriendsPlus/Page44/E1/1.jpg" style='height:16cm; margin-left:'>
							</div>
          		<div style=" position: absolute; top: 89px; left: 458px; "><input id='0' style="width:0px"></input></div>
          		<div style=" position: absolute; top: 125px; left: 458px; "><input id='1' style="width:0px"></input></div>
          		<div style=" position: absolute; top: 162px; left: 458px; "><input id='2' style="width:0px"></input></div>
          		<div style=" position: absolute; top: 198px; left: 458px; "><input id='3' style="width:0px"></input></div>
          		<div style=" position: absolute; top: 235px; left: 458px; "><input id='4' style="width:0px"></input></div>
          		<div style=" position: absolute; top: 271px; left: 458px; "><input id='5' style="width:0px"></input></div>
          		<div style=" position: absolute; top: 271px; left: 495.5px; "><input id='6' style="width:0px"></input></div>
          		<div style=" position: absolute; top: 271px; left: 532.5px; "><input id='7' style="width:0px"></input></div>
          		<div style=" position: absolute; top: 235px; left: 46px; "><input id='8' style="width:0px"></input></div>
          		<div style=" position: absolute; top: 235px; left: 83.5px; "><input id='9' style="width:0px"></input></div>
          		<div style=" position: absolute; top: 235px; left: 121px; "><input id='10' style="width:0px"></input></div>
          		<div style=" position: absolute; top: 235px; left: 158.5px; "><input id='11' style="width:0px"></input></div>
          		<div style=" position: absolute; top: 235px; left: 233px; "><input id='12' style="width:0px"></input></div>
          		<div style=" position: absolute; top: 235px; left: 271px; "><input id='13' style="width:0px"></input></div>
          		<div style=" position: absolute; top: 235px; left: 308px; "><input id='14' style="width:0px"></input></div>
          		<div style=" position: absolute; top: 235px; left: 383.5px; "><input id='15' style="width:0px"></input></div>
          		<div style=" position: absolute; top: 235px; left: 421px; "><input id='16' style="width:0px"></input></div>
          		<div style=" position: absolute; top: 199px; left: 158.5px; "><input id='17' style="width:0px"></input></div>
          		<div style=" position: absolute; top: 162px; left: 158.5px; "><input id='18' style="width:0px"></input></div>
          		<div style=" position: absolute; top: 199px; left: 196px; "><input id='19' style="width:0px"></input></div>
          		<div style=" position: absolute; top: 199px; left: 233px; "><input id='20' style="width:0px"></input></div>

          		<div style=" position: absolute; top: 125.5px; left: 233px; "><input id='21' style="width:0px"></input></div>
          		<div style=" position: absolute; top: 162px; left: 233px; "><input id='22' style="width:0px"></input></div>
          		<div style=" position: absolute; top: 271.5px; left: 233px; "><input id='23' style="width:0px"></input></div>
          		<div style=" position: absolute; top: 307px; left: 233px; "><input id='24' style="width:0px"></input></div>
          		<div style=" position: absolute; top: 343.5px; left: 233px; "><input id='25' style="width:0px"></input></div>
          		<div style=" position: absolute; top: 380px; left: 233px; "><input id='26' style="width:0px"></input></div>

              <div style=" position: absolute; top: 380px; left: 83.5px; "><input id='27' style="width:0px"></input></div>
          		<div style=" position: absolute; top: 380px; left: 121px; "><input id='28' style="width:0px"></input></div>
          		<div style=" position: absolute; top: 380px; left: 158.5px; "><input id='29' style="width:0px"></input></div>
          		<div style=" position: absolute; top: 380px; left: 196px; "><input id='30' style="width:0px"></input></div>
          		<div style=" position: absolute; top: 380px; left: 271px; "><input id='31' style="width:0px"></input></div>
          		<div style=" position: absolute; top: 380px; left: 308.5px; "><input id='32' style="width:0px"></input></div>
              <div style=" position: absolute; top: 380px; left: 345.5px; "><input id='33' style="width:0px"></input></div>
          		<div style=" position: absolute; top: 380px; left: 383.5px; "><input id='34' style="width:0px"></input></div>
          		
          		<div style=" position: absolute; top: 343.5px; left: 308.5px; "><input id='35' style="width:0px"></input></div>
          		<div style=" position: absolute; top: 416px; left: 308.5px; "><input id='36' style="width:0px"></input></div>
          		<div style=" position: absolute; top: 453px; left: 308.5px; "><input id='37' style="width:0px"></input></div>
          		<div style=" position: absolute; top: 490px; left: 308.5px; "><input id='38' style="width:0px"></input></div>
          		<div style=" position: absolute; top: 526px; left: 308.5px; "><input id='39' style="width:0px"></input></div>
          		<div style=" position: absolute; top: 563px; left: 308.5px; "><input id='40' style="width:0px"></input></div>

              <div style=" position: absolute; top: 343.5px; left: 383.5px; "><input id='40' style="width:0px"></input></div>
          		<div style=" position: absolute; top: 416px; left: 383.5px; "><input id='41' style="width:0px"></input></div>
          		<div style=" position: absolute; top: 453px; left: 383.5px; "><input id='42' style="width:0px"></input></div>
          		<div style=" position: absolute; top: 490px; left: 383.5px; "><input id='43' style="width:0px"></input></div>
          		<div style=" position: absolute; top: 526px; left: 383.5px; "><input id='44' style="width:0px"></input></div>
          		<div style=" position: absolute; top: 563px; left: 383.5px; "><input id='45' style="width:0px"></input></div>

              <div style=" position: absolute; top: 526px; left: 420.5px; "><input id='46' style="width:0px"></input></div>
          		<div style=" position: absolute; top: 526px; left: 458px; "><input id='47' style="width:0px"></input></div>
          		<div style=" position: absolute; top: 526px; left: 495.5px; "><input id='48' style="width:0px"></input></div>
          		<div style=" position: absolute; top: 526px; left: 533px; "><input id='49' style="width:0px"></input></div>
          		<div style=" position: absolute; top: 526px; left: 570.5px; "><input id='50' style="width:0px"></input></div>
          		<div style=" position: absolute; top: 526px; left: 608px; "><input id='51' style="width:0px"></input></div>
          
						</div>
      </div>

      </div>
		

						`,
        answer: [
          "D",
          "O",
          "N",
          "A",
          "T",
          "E",
          "N",
          "D",
          "S",
          "I",
          "G",
          "N",
          "P",
          "R",
          "O",
          "E",
          "S",
          "A",
          "B",
          "I",
          "M",
          "C",
          "A",
          "A",
          "I",
          "G",
          "N",
          "V",
          "O",
          "L",
          "U",
          "T",
          "E",
          "E",
          "R",
          "B",
          "L",
          "I",
          "E",
          "V",
          "E",
          "P",
          "O",
          "P",
          "O",
          "S",
          "E",
          "U",
          "P",
          "P",
          "O",
          "R",
          "T",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 7",
    id: "FP8-WB-demo-2023-U7-P44-E2",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page44/Key/E2answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 170,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "2",
        star: 2,
        title: `
        Complete the sentences using the correct form of the verbs in exercise 1. 					`,
        color: "#0067B4",
      },
    ],
    questionImage: [],
    maxLength: 15,
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
       <div style='line-height:2.5'>
       They have <b style='border-bottom:1px solid gray; color: gray'>banned</b>  smoking in all public buildings in the UK.<br>
       <b>1</b>&ensp;You may have to <input/> for what you believe in one day.  <br> 
       <b>2</b>&ensp;We are <input/> against pollution in the town centre today.  <br> 
       <b>3</b>&ensp;They <input/> a charity that raises money for children’s hospitals.     <br> 
       <b>4</b>&ensp;My friend has <input/>  a petition for free school meals.     <br> 
       <b>5</b>&ensp;I want to <input/>  at an animal charity.     <br> 
       <b>6</b>&ensp;Please <input/> some money to the people who lost their homes in the disaster.
       <br> 

       </div>
		

						`,
        answer: [
          "campaign",
          "protesting",
          "support",
          "signed",
          "volunteer",
          "donate",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 7",
    id: "FP8-WB-demo-2023-U7-P44-E3",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page44/Key/E3answerKey.png",
    titleQuestion: [
      {
        num: "3",
        star: 2,
        title: `
        Complete the article with the correct words.
 					`,
        color: "#0067B4",
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 3,
          // border: "none",
          border: "2px solid #000000", // Sửa giá trị border thành "1px solid #000000"
          borderRadius: "50%",
          borderColor: "transparent",
          color: "#000000",
        },
        selectWordStyle: {
          padding: 5,
          borderColor: "#000000",
        },

        limitSelect: 1,
        listWords: [
          "donating / volunteering / boycotting", //0
          "support / ban / protest", //1
          "believe / support / aim", //2
          "ban / protest / propose", //3
          "campaigned / proposed / aimed", //4
          "campaigned / believed / volunteered", //5
        ],
        answers: ["1-0", "0-0", "2-8", "3-0", "4-4", "5-4"],
        initialValue: [],
      },
      Layout: `
      
      
    <div style=" position: relative; padding-top:10px ">
    <div><img src="img/FriendsPlus/Page44/E3/1.jpg" style='width: 17cm'/></div>
            
            <div style="position: absolute; top:30px; left: 30px; font-size:20px; width:560px; line-height: 29px ">
            How can we campaign / end / sign poverty? Experts say that <br> <sup>1</sup> <b><input id='0' type='Circle' /> </b>
             money to charity isn’t enough. However, we can use ‘microloans’ to <sup>2</sup> <b><input id='1' type='Circle' /> </b> people.
              Many poor people <sup>3</sup> <b><input id='2' type='Circle' /> </b> to start their own businesses, but normal banks
               <sup>4</sup> <b><input id='3' type='Circle' /> </b> them from borrowing the money they need. Bangladeshi businessman Muhammad Yunus
                <sup>5</sup> <b><input id='4' type='Circle' /> </b> a new idea: ‘microfinance’. This is when wealthy people make very small 
                loans online that allow poor people to start a business and earn their own money. Lhamu from Tibet says that microloans
                 have changed her life. Her family <sup>6</sup> <b><input id='5' type='Circle' /> </b>
            
            </div>
            <div style="position: absolute; top:420px; left: 13px; ">
            <div style='display:flex'>
            <img src="img/FriendsPlus/Page44/E3/2.jpg" style='width: 7cm; height: 6.5cm; margin-right: 10px'/>
            <div style='font-size:20px; width: 320px;  line-height: 29px '>
            in her abilities, and they persuaded her to develop her clothes business. 
            The small loan helped her to buy a laptop, get training and build a website.
             Her business is now 300% bigger!
            </div>
            </div>
          

            
            </div>
            
				</div>


      `,
    },
  },
  4: {
    unit: "Unit 7",
    id: "FP8-WB-demo-2023-U7-P44-E4",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6v2,
    // maxLength: 6,
    inputSize: 150,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "22px",
    },
    titleQuestion: [
      {
        num: "4",
        star: 3,
        title: `
        Answer the questions. Give reasons for your answers. 					`,
        color: "#0067B4",
      },
    ],
    questionImage: [],
    maxLength: 210,
    hideBtnFooter: true,
    isHiddenCheck: true,
    textareaStyle: {
      // background: 'none',
      width: 850,
      fontSize: 22,
      height: 100,
    },
    questions: [
      {
        title: `
       <div style='line-height:50px; font-size: 22px'>
       What would you like to ban? What would this change?<br><b style='border-bottom:1px solid gray; color: gray'>I would like to ban mobile phones from restaurants. I think people might talk more without mobile phones at the table. </b> <br>
       <div style='display:flex; line-height:40px; font-size: 22px'>
       <b>1</b>
        <div style='margin-left:10px'>       Have you ever seen or signed an online petition? What was its aim?
        </div>
       </div>
       <textarea id='0' rows='2'></textarea>

       <div style='display:flex'>
       <b>2</b>
        <div style='margin-left:10px'>       What types of things do people usually campaign for? 
        </div>
       </div>
       <textarea id='1' rows='2'></textarea>

       <div style='display:flex'>
       <b>3</b>
        <div style='margin-left:10px'>       Can you propose a way to make studying more fun? 
        </div>
       </div>
       <textarea id='2' rows='2'></textarea>

       <div style='display:flex'>
       <b>4</b>
        <div style='margin-left:10px'>       What types of volunteer work have you heard of? Where would you volunteer?  
        </div>
       </div>
       <textarea id='3' rows='2'></textarea>

       <div style='display:flex'>
       <b>5</b>
        <div style='margin-left:10px'>       What charities would you donate to? Who or what do they support? 
        </div>
       </div>
       <textarea id='4' rows='2'></textarea>

      
      
       </div>
		

						`,
        answer: [""],
      },
    ],
  },
};

export default json;
