import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 6",
    id: "FP8-WB-demo-2023-U6-P66-E1",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page66/Key/E1answerKey.png",
    component: T6v2,
    maxLength: 30,
    inputSize: 300,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      textAlign: "center",
      fontSize: "22px",
      // fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "1",

        title: `
						
        Complete the sentences about ability using <i>can,
        could</i> or <i>be able to</i> and the verbs in brackets.
												`,
        color: "#4d3c98",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
      <div style='line-height:50px; font-size: 22px'>
      He <b><u style='color: gray;'>&ensp;can’t swim&ensp;</u></b> very well yet, but he’s learning. (swim)
          
      <div><b>1.</b>&ensp; I think she # the race next week. She’s done so much training! (win)</div>

      <div><b>2.</b>&ensp; They # by car tomorrow. There’s too much traffic on Fridays. (come)</div>

      <div><b>3.</b>&ensp; I # when I was four. (read)</div>

      <div><b>4.</b>&ensp; I can’t sing very well, but I # the guitar. (play)</div>

      <div><b>5.</b>&ensp; My cousin # delicious meals when he was just a kid. (cook)</div>

      <div><b>6.</b>&ensp; Salim is deaf. He # anything. (hear)</div>
          
      </div>
												`,
        answer: [
          `'ll be able to win`,
          `won't be able to come`,
          `couldn't read`,
          `can play`,
          `could cook`,
          `can't hear`,
        ],
      },
    ],
  },
  2: {
    unit: "Unit 6",
    id: "FP8-WB-demo-2023-U6-P66-E2",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page66/Key/E2answerKey.png",
    component: Circle_Write,
    // maxLength: 6,
    inputSize: 170,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      textAlign: "center",
      fontSize: "20px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "2",

        title: `
					
        Choose the correct words. 
											`,
        color: "#4d3c98",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 100, fontSize: 19, textAlign: "center" },
        answers: [""],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 2px",
          // borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [
          `couldn’t / can / able_to`, //1
          `couldn’t / not_able / can`, //1
          `could / won’t_be_able_to / couldn’t`, //1
          `can / able_to / can’t`, //1
          ` will_be_able_to / can / won’t_able_to`,
          `can / able_to / be_able_to`,
          `could / can / will_be_able`,
        ],
        answers: ["0-4", "1-0", "2-8", "3-8", "5-8", "6-4", "4-2"],
        initialValue: [],
      },
      Layout: `
      <div style=" position: relative; padding-top:10px; line-height: 35px ">
      <div><img src="img/FriendsPlus/Page66/E2/1.jpg" style='max-width:75%'/></div>
              
        <div style="position: absolute; top:40px; left: 30px; font-size:21px;width:726px; ">
          Emotional intelligence (or EI) is a very important life skill. It means that you <b>able / could / <span style='padding: 4px ;border: 2px solid black;border-radius:50%'>can</span></b>
          recognise your emotions and manage them, too. Most of us <sup><b>1</b></sup> <b><input id='0' type='Circle' /></b> remember
          a time when, as a young child, we were upset, but we <sup><b>2</b></sup> <b><input id='1' type='Circle' /></b>  say why, and 
          we <sup><b>3</b></sup> <b><input id='2' type='Circle' /></b>  control our feelings. Experts say that we <sup><b>4</b></sup> <b><input id='3' type='Circle' /></b>
          start to identify or control our own emotions until we are around five years old. Psychologists say that emotional intelligence will be extremely important to our working lives in the future. Soon, 
          employers <sup><b>5</b></sup> <b><input id='4' type='Circle' /></b> check how much EI you have. If you don’t have 
          good EI, you won’t <sup><b>6</b></sup> <b><input id='5' type='Circle' /></b>  get the career you want. Now, they are creating EI 
          courses, so that we<sup><b>7</b></sup> <b><input id='6' type='Circle' /></b> improve this important skill.
              
            </div>
            </div>
          </div>
      `,
    },
  },
  3: {
    unit: "Unit 6",
    id: "FP8-WB-demo-2023-U6-P66-E3",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page66/Key/E3answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 350,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      textAlign: "center",
      fontSize: "22px",
      // fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "3",

        title: `
					
        Find and correct the mistakes.
											`,
        color: "#4d3c98",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
				<div style='font-size: 22px; line-height: 37px'>
				<div>Let’s go! We <span style="text-decoration:line-through">haven’t to be</span> late. <b style="margin-left:15px; color: gray"><u>mustn’t be</u></b></div>
				<div><b style="margin-right:15px">1</b>We’re playing in the final of the competition 
        next week, so I need be fit.</div>
        <div>Mistake: #</div>
        <div>Correct: #</div>
				<div><b style="margin-right:15px">2</b>Should we to go to bed earlier?</div>
        <div>Mistake: #</div>
        <div>Correct: #</div>
				<div><b style="margin-right:15px">3</b>You don’t have to be late for class.</div>
        <div>Mistake: #</div>
        <div>Correct: #</div>
				<div><b style="margin-right:15px">4</b>Do you must wear a uniform at your school?</div>
        <div>Mistake: #</div>
        <div>Correct: #</div>
				<div><b style="margin-right:15px">5</b>They should don’t talk during class.</div>
        <div>Mistake: #</div>
        <div>Correct: #</div>
				<div><b style="margin-right:15px">6</b>Have you to do extra homework?</div>
        <div>Mistake: #</div>
        <div>Correct: #</div>
				</div>	`,
        answer: [
          "need be fit",
          "need to be",
          "we to go",
          "we go",
          "don't have to be",
          "mustn't be",
          "Do you must wear",
          "Do you have to wear",
          "should don't talk",
          "shouldn't talk",
          "Have you to do",
          "Do you have to do",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 6",
    id: "FP8-WB-demo-2023-U5-P66-E4",
    // audio: "Audios/2.18.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page66/Key/E4answerKey.png",
    component: T6v2,
    maxLength: 24,
    inputSize: 200,
    // textAlign:'center',
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      textAlign: "center",
      fontSize: "22px",
      // fontWeight: "bold",
    },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "4",
        title: `
        Complete the dialogue with <i>must, mustn't, don't
        have to, should</i> or <i>shouldn't</i>.

					`,
        color: "#4d3c98",
      },
    ],
    questions: [
      {
        title: `
    <div style='line-height:45px; font-size: 22px'>
        <div style='display:flex'> <b>Joe</b> <span style='margin-left:20px'> What do I need to know about this sport?</span></div>

        <div style='display:flex'> <b>Max</b> <span style='margin-left:20px'>  First of all, you <b><u style='color: gray'>&ensp;should&ensp;</u></b> wear warm 
        clothes because it’s a winter game that we play outdoors. Players <b><sup>1</sup></b> # usually wear green, but people don’t worry too much about that.</span></div>

        <div style='display:flex'> <b>Joe</b> <span style='margin-left:20px'> OK, and how about safety equipment?</span></div>

        <div style='display:flex'> <b>Max</b> <span style='margin-left:20px'>Oh, you <b><sup>2</sup></b> # wear that because the ball isn’t hard. And the rules say that you <br><b><sup>3</sup></b> # touch another player, so it’s a safe game.</span></div>

        <div style='display:flex'> <b>Joe</b> <span style='margin-left:20px'>Good! I’ll tell my mum that she <b><sup>4</sup></b> #  worry about me playing it, then! What else?</span></div>

        <div style='display:flex'> <b>Max</b> <span style='margin-left:20px'>Well, it’s OK to pick up the ball and throw 
        it, but you <b><sup>5</sup></b> # run with it in your hands.</span></div>

        <div style='display:flex'> <b>Joe</b> <span style='margin-left:20px'>And how about kicking it with your feet?</span></div>

        <div style='display:flex'> <b>Max</b> <span style='margin-left:20px'>Everyone can do that, except the goalies. They <b><sup>6</sup></b> # only use their hands.</span></div>

        <div style='display:flex'> <b>Joe</b> <span style='margin-left:20px'>Oh! It’s very different from football, then?</span></div>

        <div style='display:flex'> <b>Max</b> <span style='margin-left:20px'>Yes, it is.</span></div>

    </div>
		
	
			
								`,
        answer: [
          `should`,
          `don't have to`,
          `mustn't`,
          `shouldn't`,
          `mustn't`,
          `must`,
        ],
      },
    ],
  },
  5: {
    unit: "Unit 6",
    id: "FP8-WB-demo-2023-U6-P66-E5",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page66/Key/E5answerKey.png",
    component: T6v2,
    maxLength: 14,
    inputSize: 150,
    stylesTextInput: {
      //   background: 'transparent',
      //   border: 'none',
      //   borderBottom: '1px solid black',
      textAlign: "center",
      fontSize: "23px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "5",

        title: `
						
        Complete the adverbs to order the information.
												`,
        color: "#4d3c98",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
      <div style='line-height:50px; width: 800px; font-size: 23px'>
      I agree that sports lessons are really good for students for a number of reasons. <sup>1</sup> F # ,
      I think it’s important for our health to do regular exercise. <sup>2</sup> S # , 
      working in a team helps students to develop 
      useful skills. And <sup>3</sup> f #  / l # , 
      competitions can be fun for everyone.
          
      </div>
												`,
        answer: [`irstly`, `econdly`, `inally`, `astly`],
      },
    ],
  },
};

export default json;
