import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import MatchDotsWrite from "../../components/ExcerciseTypes/LineTo/MatchDotsWrite";

const json = {
  // 1: {
  //   unit: "Unit 8",
  //   id: "FP8-WB-demo-2023-U8-P50-E1",
  //   audio: "",

  //   video: "",
  //   // recorder: true,
  //   exerciseKey: "/img/FriendsPlus/Page50/Key/E1answerKey.jpg",
  //   // hideBtnFooter: true,

  //   component: T6v2,
  //   // maxLength: 6,
  //   inputSize: 320,
  //   stylesTextInput: {
  //     // background: 'transparent',
  //     // border: 'none',
  //     textAlign: "center",
  //     fontSize: "24px",
  //     fontWeight: "bold",
  //   },
  //   titleQuestion: [
  //     {
  //       num: "1",
  //       star: 1,

  //       title: `
  //       Find eight more film and book genre words in the wordsearch.

  // 							`,
  //       color: "rgb(0,103,180)",
  //     },
  //   ],
  //   questionImage: [],
  //   // hideBtnFooter: true,
  //   isHiddenCheck: true,
  //   questions: [
  //     {
  //       title: `
  //       <div style='display:flex'>
  //       <img src='img/FriendsPlus/Page50/E1/1.jpg' width='50%'>

  //       <div style='margin-left:20px'>
  //       Which two words can you use to describe films, but not books? <br>
  //       <u>ANSWER:</u>&ensp;<input/>
  //       </div>

  //       </div>

  // 							`,
  //       answer: [`animation and musical`],
  //     },
  //   ],
  // },
  1: {
    // Exercise num
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P50-E1",
    // audio: 'Audios/Track 08.mp3',
    video: "",
    component: MatchDotsWrite,
    exerciseKey: "/img/FriendsPlus/Page50/Key/E1answerKey.jpg",
    titleQuestion: [
      {
        num: "1",
        star: 1,
        title: `
        Find eight more film and book genre words in the wordsearch. 
								`,
        color: "rgb(0,103,180)",
      },
    ],
    // inputSize: 50,
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {
          width: 200,
          height: 28,
          borderBottom: "1px solid black",
          textAlign: "center",
          fontSize: 21,
          // color: "black",
          marginTop: "3px",
          maxLength: 100,
          // /backgroundColor: "blue",
        },
        isHiddenCheck: true,

        answers: ["animation / musical", "musical / animation"],
        initialValue: [],
      },
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          //HANG 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "59px",
              left: "35px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "59px",
              left: "83px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "59px",
              left: "132px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //3

          {
            boxMatchStyle: {
              position: "absolute",
              top: "59px",
              left: "180px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "59px",
              left: "228px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "59px",
              left: "276px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "59px",
              left: "325px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "59px",
              left: "373px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "59px",
              left: "421px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "59px",
              left: "469px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //10
          {
            boxMatchStyle: {
              position: "absolute",
              top: "59px",
              left: "518px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //11
          {
            boxMatchStyle: {
              position: "absolute",
              top: "59px",
              left: "566px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //12

          //HANG 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "105px",
              left: "35px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "105px",
              left: "83px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "105px",
              left: "132px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //3

          {
            boxMatchStyle: {
              position: "absolute",
              top: "105px",
              left: "180px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "105px",
              left: "228px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "105px",
              left: "276px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "105px",
              left: "325px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "105px",
              left: "373px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "105px",
              left: "421px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "105px",
              left: "469px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //10
          {
            boxMatchStyle: {
              position: "absolute",
              top: "105px",
              left: "518px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //11
          {
            boxMatchStyle: {
              position: "absolute",
              top: "105px",
              left: "566px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //12

          //HANG 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "151px",
              left: "35px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "151px",
              left: "83px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "151px",
              left: "132px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //3

          {
            boxMatchStyle: {
              position: "absolute",
              top: "151px",
              left: "180px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "151px",
              left: "228px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "151px",
              left: "276px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "151px",
              left: "325px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "151px",
              left: "373px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "151px",
              left: "421px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "151px",
              left: "469px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //10
          {
            boxMatchStyle: {
              position: "absolute",
              top: "151px",
              left: "518px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //11
          {
            boxMatchStyle: {
              position: "absolute",
              top: "151px",
              left: "566px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //12

          //HANG 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "197px",
              left: "35px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "197px",
              left: "83px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "197px",
              left: "132px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //3

          {
            boxMatchStyle: {
              position: "absolute",
              top: "197px",
              left: "180px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "197px",
              left: "228px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "197px",
              left: "276px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "197px",
              left: "325px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "197px",
              left: "373px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "197px",
              left: "421px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "197px",
              left: "469px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //10
          {
            boxMatchStyle: {
              position: "absolute",
              top: "197px",
              left: "518px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //11
          {
            boxMatchStyle: {
              position: "absolute",
              top: "197px",
              left: "566px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //12

          //HANG 5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "243px",
              left: "35px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "243px",
              left: "83px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "243px",
              left: "132px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //3

          {
            boxMatchStyle: {
              position: "absolute",
              top: "243px",
              left: "180px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "243px",
              left: "228px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "243px",
              left: "276px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "243px",
              left: "325px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "243px",
              left: "373px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "243px",
              left: "421px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "243px",
              left: "469px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //10
          {
            boxMatchStyle: {
              position: "absolute",
              top: "243px",
              left: "518px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //11
          {
            boxMatchStyle: {
              position: "absolute",
              top: "243px",
              left: "566px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //12

          //HANG 6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "289px",
              left: "35px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "289px",
              left: "83px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "289px",
              left: "132px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //3

          {
            boxMatchStyle: {
              position: "absolute",
              top: "289px",
              left: "180px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "289px",
              left: "228px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "289px",
              left: "276px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "289px",
              left: "325px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "289px",
              left: "373px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "289px",
              left: "421px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "289px",
              left: "469px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //10
          {
            boxMatchStyle: {
              position: "absolute",
              top: "289px",
              left: "518px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //11
          {
            boxMatchStyle: {
              position: "absolute",
              top: "289px",
              left: "566px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //12

          //HANG 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "334px",
              left: "35px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "334px",
              left: "83px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "334px",
              left: "132px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //3

          {
            boxMatchStyle: {
              position: "absolute",
              top: "334px",
              left: "180px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "334px",
              left: "228px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "334px",
              left: "276px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "334px",
              left: "325px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "334px",
              left: "373px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "334px",
              left: "421px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "334px",
              left: "469px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //10
          {
            boxMatchStyle: {
              position: "absolute",
              top: "334px",
              left: "518px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //11
          {
            boxMatchStyle: {
              position: "absolute",
              top: "334px",
              left: "566px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //12

          //HANG 8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "380px",
              left: "35px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "380px",
              left: "83px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "380px",
              left: "132px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //3

          {
            boxMatchStyle: {
              position: "absolute",
              top: "380px",
              left: "180px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "380px",
              left: "228px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "380px",
              left: "276px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "380px",
              left: "325px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "380px",
              left: "373px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "380px",
              left: "421px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "380px",
              left: "469px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //10
          {
            boxMatchStyle: {
              position: "absolute",
              top: "380px",
              left: "518px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //11
          {
            boxMatchStyle: {
              position: "absolute",
              top: "380px",
              left: "566px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //12

          //HANG 9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "426px",
              left: "35px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "426px",
              left: "83px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "426px",
              left: "132px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //3

          {
            boxMatchStyle: {
              position: "absolute",
              top: "426px",
              left: "180px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "426px",
              left: "228px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "426px",
              left: "276px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "426px",
              left: "325px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "426px",
              left: "373px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "426px",
              left: "421px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "426px",
              left: "469px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //10
          {
            boxMatchStyle: {
              position: "absolute",
              top: "426px",
              left: "518px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //11
          {
            boxMatchStyle: {
              position: "absolute",
              top: "426px",
              left: "566px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //12

          //HANG 10
          {
            boxMatchStyle: {
              position: "absolute",
              top: "472px",
              left: "35px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "472px",
              left: "83px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "472px",
              left: "132px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //3

          {
            boxMatchStyle: {
              position: "absolute",
              top: "472px",
              left: "180px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "472px",
              left: "228px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "472px",
              left: "276px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "472px",
              left: "325px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "472px",
              left: "373px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "472px",
              left: "421px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "472px",
              left: "469px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //10
          {
            boxMatchStyle: {
              position: "absolute",
              top: "472px",
              left: "518px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //11
          {
            boxMatchStyle: {
              position: "absolute",
              top: "472px",
              left: "566px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //12

          //HANG 11
          {
            boxMatchStyle: {
              position: "absolute",
              top: "518px",
              left: "35px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "518px",
              left: "83px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "518px",
              left: "132px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //3

          {
            boxMatchStyle: {
              position: "absolute",
              top: "518px",
              left: "180px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "518px",
              left: "228px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "518px",
              left: "276px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "518px",
              left: "325px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "518px",
              left: "373px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "518px",
              left: "421px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "518px",
              left: "469px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //10
          {
            boxMatchStyle: {
              position: "absolute",
              top: "518px",
              left: "518px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //11
          {
            boxMatchStyle: {
              position: "absolute",
              top: "518px",
              left: "566px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //12

          //HANG 12
          {
            boxMatchStyle: {
              position: "absolute",
              top: "564px",
              left: "35px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "564px",
              left: "83px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "564px",
              left: "132px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //3

          {
            boxMatchStyle: {
              position: "absolute",
              top: "564px",
              left: "180px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "564px",
              left: "228px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "564px",
              left: "276px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "564px",
              left: "325px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "564px",
              left: "373px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "564px",
              left: "421px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "564px",
              left: "469px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //10
          {
            boxMatchStyle: {
              position: "absolute",
              top: "564px",
              left: "518px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //11
          {
            boxMatchStyle: {
              position: "absolute",
              top: "564px",
              left: "566px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //12

          //HANG 13
          {
            boxMatchStyle: {
              position: "absolute",
              top: "610px",
              left: "35px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "610px",
              left: "83px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "610px",
              left: "132px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //3

          {
            boxMatchStyle: {
              position: "absolute",
              top: "610px",
              left: "180px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "610px",
              left: "228px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "610px",
              left: "276px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "610px",
              left: "325px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "610px",
              left: "373px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "610px",
              left: "421px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "610px",
              left: "469px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //10
          {
            boxMatchStyle: {
              position: "absolute",
              top: "610px",
              left: "518px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //11
          {
            boxMatchStyle: {
              position: "absolute",
              top: "610px",
              left: "566px",
              width: 44,
              height: 42,
              //backgroundColor: "rgb(243,164,181)",
              // border: "1px solid",
            },
          }, //12
        ],
        answers: [
          "1-9",
          "27-31",
          "37-85",
          "49-55",
          "148-52",
          "134-141",
          "149-154",
          "106-34",
        ],
        initialValue: [],
      },
      Layout: `
      <div><i style='font-size:19px; color: rgb(245,54,92)'>*Choose the first and last character of the word.</i></div>
			<div style='position: relative; text-align:left; margin-bottom: 100px' >
       
      <div style=""><img src='img/FriendsPlus/Page50/E1/1.jpg' style='width: 16cm' /></div>    
				<div style="margin-left:20px;margin-top:20px; font-size: 21px; line-height: 40px">
    			<div>Which two words can you use to describe films, 
          but not books?</div>
            
            <div><input id='0'/> and <input id='1'/></div>
            
            </div>
			</div>  

			  <input id='0' type= 'boxMatch' />
			  <input id='1' type= 'boxMatch' />
			  <input id='2' type= 'boxMatch' />
			  <input id='3' type= 'boxMatch' />
        <input id='4' type= 'boxMatch' />
			  <input id='5' type= 'boxMatch' />
        <input id='6' type= 'boxMatch' />
			  <input id='7' type= 'boxMatch' />
        <input id='8' type= 'boxMatch' />
        <input id='9' type= 'boxMatch' />
        <input id='10' type= 'boxMatch' />
			  <input id='11' type= 'boxMatch' />

        <input id='12' type= 'boxMatch' />
			  <input id='13' type= 'boxMatch' />
			  <input id='14' type= 'boxMatch' />
			  <input id='15' type= 'boxMatch' />
        <input id='16' type= 'boxMatch' />
			  <input id='17' type= 'boxMatch' />
        <input id='18' type= 'boxMatch' />
			  <input id='19' type= 'boxMatch' />
        <input id='20' type= 'boxMatch' />
        <input id='21' type= 'boxMatch' />
        <input id='22' type= 'boxMatch' />
			  <input id='23' type= 'boxMatch' />

        <input id='24' type= 'boxMatch' />
			  <input id='25' type= 'boxMatch' />
			  <input id='26' type= 'boxMatch' />
			  <input id='27' type= 'boxMatch' />
        <input id='28' type= 'boxMatch' />
			  <input id='29' type= 'boxMatch' />
        <input id='30' type= 'boxMatch' />
			  <input id='31' type= 'boxMatch' />
        <input id='32' type= 'boxMatch' />
        <input id='33' type= 'boxMatch' />
        <input id='34' type= 'boxMatch' />
			  <input id='35' type= 'boxMatch' />

        <input id='36' type= 'boxMatch' />
			  <input id='37' type= 'boxMatch' />
			  <input id='38' type= 'boxMatch' />
			  <input id='39' type= 'boxMatch' />
        <input id='40' type= 'boxMatch' />
			  <input id='41' type= 'boxMatch' />
        <input id='42' type= 'boxMatch' />
			  <input id='43' type= 'boxMatch' />
        <input id='44' type= 'boxMatch' />
        <input id='45' type= 'boxMatch' />
        <input id='46' type= 'boxMatch' />
			  <input id='47' type= 'boxMatch' />

        <input id='48' type= 'boxMatch' />
			  <input id='49' type= 'boxMatch' />
			  <input id='50' type= 'boxMatch' />
			  <input id='51' type= 'boxMatch' />
        <input id='52' type= 'boxMatch' />
			  <input id='53' type= 'boxMatch' />
        <input id='54' type= 'boxMatch' />
			  <input id='55' type= 'boxMatch' />
        <input id='56' type= 'boxMatch' />
        <input id='57' type= 'boxMatch' />
        <input id='58' type= 'boxMatch' />
			  <input id='59' type= 'boxMatch' />

        <input id='60' type= 'boxMatch' />
			  <input id='61' type= 'boxMatch' />
			  <input id='62' type= 'boxMatch' />
			  <input id='63' type= 'boxMatch' />
        <input id='64' type= 'boxMatch' />
			  <input id='65' type= 'boxMatch' />
        <input id='66' type= 'boxMatch' />
			  <input id='67' type= 'boxMatch' />
        <input id='68' type= 'boxMatch' />
        <input id='69' type= 'boxMatch' />
        <input id='70' type= 'boxMatch' />
			  <input id='71' type= 'boxMatch' />

        
			  <input id='72' type= 'boxMatch' />
			  <input id='73' type= 'boxMatch' />
        <input id='74' type= 'boxMatch' />
			  <input id='75' type= 'boxMatch' />
        <input id='76' type= 'boxMatch' />
			  <input id='77' type= 'boxMatch' />
        <input id='78' type= 'boxMatch' />
        <input id='79' type= 'boxMatch' />
        <input id='80' type= 'boxMatch' />
			  <input id='81' type= 'boxMatch' />
        <input id='82' type= 'boxMatch' />
			  <input id='83' type= 'boxMatch' />

        <input id='84' type= 'boxMatch' />
			  <input id='85' type= 'boxMatch' />
			  <input id='86' type= 'boxMatch' />
			  <input id='87' type= 'boxMatch' />
        <input id='88' type= 'boxMatch' />
			  <input id='89' type= 'boxMatch' />
        <input id='90' type= 'boxMatch' />
			  <input id='91' type= 'boxMatch' />
        <input id='92' type= 'boxMatch' />
        <input id='93' type= 'boxMatch' />
        <input id='94' type= 'boxMatch' />
			  <input id='95' type= 'boxMatch' />

        <input id='96' type= 'boxMatch' />
			  <input id='97' type= 'boxMatch' />
			  <input id='98' type= 'boxMatch' />
			  <input id='99' type= 'boxMatch' />
        <input id='100' type= 'boxMatch' />
			  <input id='101' type= 'boxMatch' />
        <input id='102' type= 'boxMatch' />
			  <input id='103' type= 'boxMatch' />
        <input id='104' type= 'boxMatch' />
        <input id='105' type= 'boxMatch' />
        <input id='106' type= 'boxMatch' />
			  <input id='107' type= 'boxMatch' />
        
			  <input id='108' type= 'boxMatch' />
			  <input id='109' type= 'boxMatch' />
			  <input id='110' type= 'boxMatch' />
			  <input id='111' type= 'boxMatch' />
        <input id='112' type= 'boxMatch' />
			  <input id='113' type= 'boxMatch' />
        <input id='114' type= 'boxMatch' />
			  <input id='115' type= 'boxMatch' />
        <input id='116' type= 'boxMatch' />
        <input id='117' type= 'boxMatch' />
        <input id='118' type= 'boxMatch' />
			  <input id='119' type= 'boxMatch' />

        <input id='120' type= 'boxMatch' />
			  <input id='121' type= 'boxMatch' />
			  <input id='122' type= 'boxMatch' />
			  <input id='123' type= 'boxMatch' />
        <input id='124' type= 'boxMatch' />
			  <input id='125' type= 'boxMatch' />
        <input id='126' type= 'boxMatch' />
			  <input id='127' type= 'boxMatch' />
        <input id='128' type= 'boxMatch' />
        <input id='129' type= 'boxMatch' />
        <input id='130' type= 'boxMatch' />
			  <input id='131' type= 'boxMatch' />

       
			  <input id='132' type= 'boxMatch' />
			  <input id='133' type= 'boxMatch' />
        <input id='134' type= 'boxMatch' />
			  <input id='135' type= 'boxMatch' />
        <input id='136' type= 'boxMatch' />
			  <input id='137' type= 'boxMatch' />
        <input id='138' type= 'boxMatch' />
        <input id='139' type= 'boxMatch' />
        <input id='140' type= 'boxMatch' />
			  <input id='141' type= 'boxMatch' />
        <input id='142' type= 'boxMatch' />
			  <input id='143' type= 'boxMatch' />

        <input id='144' type= 'boxMatch' />
			  <input id='145' type= 'boxMatch' />
			  <input id='146' type= 'boxMatch' />
			  <input id='147' type= 'boxMatch' />
        <input id='148' type= 'boxMatch' />
			  <input id='149' type= 'boxMatch' />
        <input id='150' type= 'boxMatch' />
			  <input id='151' type= 'boxMatch' />
        <input id='152' type= 'boxMatch' />
        <input id='153' type= 'boxMatch' />
        <input id='154' type= 'boxMatch' />
			  <input id='155' type= 'boxMatch' />

		  `,
    },
  },
  2: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P50-E2",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page50/Key/E2answerKey.png",
    component: T6v2,
    maxLength: 20,
    inputSize: 230,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "22px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "2",
        star: 2,
        title: `
        Match the plot with a film and / or book genre.

								`,
        color: "rgb(0,103,180)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
		<div style='line-height:50px; font-size: 22px'>
    <i>Lauren’s family is moving to a different city. What will her new neighbours and school be like?</i>
     <b style='border-bottom:1px solid black; color: gray'>&ensp;drama&ensp;</b> <br>
    <b>1</b>&ensp;Can Superman save the city again in this new book? <input/> <br>
    <b>2</b>&ensp;People are disappearing from the town, but can  the detective find out why?  <input/> <br>
    <b>3</b>&ensp;What happens when robots become more intelligent  than humans?  <input/> <br>
    <b>4</b>&ensp;In a strange land, one child must use her magic to save  her people.  <input/> <br>
    <b>5</b>&ensp;A gang follows James Franklin across six countries, by car, ship and helicopter.  <input/> <br>

    </div>
		

		
			
		`,
        answer: [
          "graphic novel",
          "mystery",
          "science fiction",
          "fantasy",
          "action",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P50-E3",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page50/Key/E3answerKey.png",
    component: T6v2,
    maxLength: 15,
    inputSize: 145,
    stylesTextInput: {
      background: "none",
      // border: 'none',
      textAlign: "center",
      fontSize: "19px",
      // fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "3",
        star: 2,
        title: `
            Choose three film and book genres that each person would like. There are two genres you don’t need. 

                  `,
        color: "rgb(0,103,180)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        
        <div style="width:1200px;border: 2px solid blue; display: flex; justify-content:center;padding: 10px 20px; border-radius: 40px">
        <div style="margin-right:20px"><span>a̶̶c̶̶t̶̶i̶̶o̶̶n̶</span></div>
        <div style="margin-right:20px"><span>dramas</span> </div>
        <div style="margin-right:20px"><span>fantasy</span></div>
        <div style="margin-right:20px"><span>graphic novels</span></div>
        <div style="margin-right:20px"><span>manga</span></div>
        <div style="margin-right:20px"><span>romance</span></div>
        <div style="margin-right:20px"><span>science fiction</span></div>
        <div style="margin-right:20px"><span>thrillers
        </span></div>
        </div>

        <div style=" position: relative; padding-top:20px ">
        <div> <img style='max-width:100%' src='img/FriendsPlus/Page50/E3/3.jpg' /> </div>
        
        <div style=" position: absolute; top:300px; left: 300px; "><input id='0' font-size='20px' width='160px' border-bottom='0px'></input></div>
        <div style=" position: absolute; top: 332px; left: 32px; "><input id='1' font-size='20px' width='160px' border-bottom='0px'></input></div>
        <div style=" position: absolute; top: 304px; left: 999px; "><input id='2' font-size='20px' width='160px' border-bottom='0px'></input></div>
        <div style=" position: absolute; top: 337px; left: 640px; "><input id='3' font-size='20px' width='160px' border-bottom='0px'></input></div>
        <div style=" position: absolute; top: 337px; left: 830px; "><input id='4' font-size='20px' width='160px' border-bottom='0px'></input></div>
    </div>
							`,
        answer: [
          "graphic novels",
          "thrillers",
          "science fiction",
          "fantasy",
          "dramas",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P50-E4",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6v2,
    // maxLength: 6,
    inputSize: 1010,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "left",
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "4",
        star: 3,

        title: `
        Answer the questions. Write complete sentences.  `,
        color: "rgb(0,103,180)",
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        Which film genres do you most enjoy? Why?  <br>
         <b style='border-bottom:1px solid gray; color: gray'>I love comedies and animation because they’re fun.  </b>  
        <div style='line-height:2'>
        <div style ='display:flex'>
        <b>1</b>
        <div style='margin-left:10px'>
        Who are your favourite actors? What types of films have they been in?  
        <br><input/>
        </div>
        </div>
        <div style ='display:flex'>
        <b>2</b>
        <div style='margin-left:10px'>
        Which film genres do you think are better at the cinema than on TV? Why?  
        <br><input/> 
        </div>
        </div>
        
    
        <div style ='display:flex;'>
        <b>3</b>
        <div style='margin-left:10px'>
        Which film or book genres do you dislike? Why?  
  <br><input/>
        </div>
        </div>

        <div style ='display:flex'>
        <b>4</b>
        <div style='margin-left:10px'>
        Which film genres should children not watch?  
 <br><input/>
        </div>
        </div>

       
        </div>
			
						`,
        answer: [],
      },
    ],
  },
};

export default json;
