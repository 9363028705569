import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P29-E12",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page29/E1/Key/E1answerkey.png",
    component: T6v2,
    inputSize: 740,
    maxLength: 80,
    stylesTextInput: {
      borderStyle: "2px dotted",
      fontSize: "22px",
      textAlign: "left",
      height: 29,
    },
    titleQuestion: [
      {
        num: "1",
        title: `
				Order the words to make questions with
				question tags.
				`,
        color: "rgb(28,190,215)",
        star: 1,
      },
    ],

    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='width: 700px; display: flex; flex-direction: column; line-height: 50px; font-size: 22px'>
					damage / don’t they / the environment / cars ?
					<u><strong style="font-style:italic; color: gray">Cars damage the environment, don’t they?</strong></u>
					<span> <strong style="margin-right:20px">1</strong> broke / bottles / didn’t they / those ?</span>
					#
					<span> <strong style="margin-right:20px">2</strong> recycled / wasn’t it / the rubbish / was ?</span>
					#
					<span> <strong style="margin-right:30px">3</strong> has he / finished / he hasn’t / the project ?</span>
					#
					<span> <strong style="margin-right:30px">4</strong> can’t see / from here / you / can you / your flat ?</span>
					#
					<span> <strong style="margin-right:30px">5</strong> cold / be / it’ll / won’t it / tomorrow ?</span>
					#
					<span> <strong style="margin-right:30px">6</strong> tired / you’re / aren’t you / feeling ?</span>
					#
					</div>
        `,
        answer: [
          `Those bottles broke, didn't they?`,
          `The rubbish was recycled, wasn't it?`,
          `He hasn't finished the project, has he?`,
          `You can't see your flat from here, can you?`,
          `It'll be cold tomorrow, won't it?`,
          `You're feeling tired, aren't you?`,
        ],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P29-E12",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page29/E2/Key/E2answerkey.png",
    component: T6v2,
    maxLength: 10,
    inputSize: 140,
    stylesTextInput: {
      borderStyle: "dotted",
      fontSize: "24px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "2",
        title: `
				Complete the dialogue with the correct
				words.
				`,
        color: "rgb(28,190,215)",
        star: 2,
      },
    ],
    textareaStyle: {
      width: 800,
    },
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='width: 700px; display: flex; flex-direction: column; gap: 10px;'>
				       <img src="img/FriendsPlus/Page29/E2/1.png" style=""/>
					   
					       <span><strong style="margin-right:30px">Mia</strong>It’s a lovely day today, <u><strong style="font-style:italic; color: gray">isn’t</strong></u> it?</span>
						
						
						   <div style="display:flex;flex-direction:row">
						<strong style="margin-right:28px">Ben</strong>   <span>I suppose so. It’s a lot nicer than last 
						Friday. It was really cold then, (1)#
						it? You remember, (2)# you? 
						I went back home to get a coat!</span>
						 </div> 


						 <div style="display:flex;flex-direction:row">
						 <strong style="margin-right:28px">Mia</strong>   <span>Oh yes, and you lost your keys,
						 (3)# you? How embarrassing!</span>
						  </div> 


						  <div style="display:flex;flex-direction:row">
						  <strong style="margin-right:28px">Ben</strong>   <span>Yes, it (4)# , wasn’t it? I called
						  my dad at work in the end.</span>
						   </div> 

						   <div style="display:flex;flex-direction:row">
						   <strong style="margin-right:28px">Mia</strong>   <span>Oh no! He doesn’t like that,
						   (5)# he?</span>
							</div> 
							

							<span><strong style="margin-right:30px">Ben</strong>No, he doesn’t!</span>

							<div style="display:flex;flex-direction:row">
							<strong style="margin-right:30px">Mia</strong>   <span>He (6)# angry, was he?</span>
							 </div> 



							 <div style="display:flex;flex-direction:row">
							<strong style="margin-right:30px">Ben</strong>   <span>No, not really. Anyway, I’ve made an 
							extra set of keys. So that won’t happen 
							again, (7)# it?</span>
							 </div> 


							 <span><strong style="margin-right:30px">Mia</strong>No, let’s hope not!
							 </span>
					</div>
        `,
        answer: [`wasn't`, `don't`, `didn't`, `was`, `does`, `wasn't`, `will`],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P29-E12",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page29/E3/Key/E3answerkey.png",
    component: T6v2,
    inputSize: 200,
    maxLength: 15,
    stylesTextInput: {
      borderStyle: "2px dotted",
      fontSize: "22px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "3",
        title: `
				Correct the words.
				`,
        color: "rgb(28,190,215)",
        star: 2,
      },
    ],
    textareaStyle: {
      width: 800,
    },
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='font-size: 22px; width: 900px; display: flex; flex-direction: column; line-height: 50px'>
				      <span>This plate is made from recycled materials, <strong style="font-style:italic;text-decoration: line-through;">aren’t 
					  they</strong> ? <u><strong style="font-style:italic; color: gray">&ensp;isn't it&ensp;</strong></u></span>


					  <span> <strong style="margin-right:20px">1 </strong> You haven’t heard about this invention, <strong>haven’t
					  you?</strong> #</span>
					  <span> <strong style="margin-right:20px">2 </strong> She isn’t interested in environmental issues, 
					  <strong>does she?</strong> #</span>
					  <span> <strong style="margin-right:20px">3 </strong> He has invented a new smartphone app, <strong>didn’t
					  he?</strong> #</span>
					  <span> <strong style="margin-right:20px">4 </strong> They aren’t going to reduce the price, <strong>, will they? 
					  </strong> #</span>
					  <span> <strong style="margin-right:20px">5 </strong> That company was developing a new type of paper, <strong>was it? </strong> #</span>
					  <span> <strong style="margin-right:20px">6 </strong> You love TV programmes about wildlife,<strong>&ensp;aren’t
					  you? </strong> #</span>


						
						 
					  
					</div>
        `,
        answer: [
          `have you`,
          `is she`,
          `hasn't he`,
          `are they`,
          `wasn't it`,
          `don't you `,
        ],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P29-E12",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page29/E4/Key/E4answerkey.png",
    component: T6v2,
    inputSize: 800,
    maxLength: 90,
    stylesTextInput: {
      borderStyle: "dotted",
      fontSize: "22px",
      textAlign: "left",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
				Write questions using question tags. Use the words and your own ideas.
				`,
        color: "rgb(28,190,215)",
        star: 3,
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style='width: 700px; display: flex; flex-direction: column; gap: 10px; font-size: 23px'>

					<div style='display: flex; flex-direction:column;flex-wrap: wrap; gap: 10px; align-items: center; justify-content: center;
					max-width: 900px; padding: 10px 80px;border-radius: 20px; border: 3px solid rgb(28,190,215);'>
				   
					<div>
					<span>best friend / speak French   &emsp; <span style="text-decoration:line-through">hot / Prague</span></span> 
					</div>
	  
					<div>
					see / this film their parents / not like / swim
					</div>

					<div>
					your holiday / Greece
					</div>
				 
				  
				   
				  </div>
				    <strong style="font-style:italic ;color: gray">It was hot in Prague last weekend, wasn’t it?</strong>


     
					<div  style="display:flex;flex-direction:row">
					<span><strong>1</strong></span>&ensp; #
					</div>

					<div  style="display:flex;flex-direction:row">
					<span><strong>2</strong></span>&ensp; #
					</div>

					<div  style="display:flex;flex-direction:row">
					<span><strong>3</strong></span>&ensp; #
					</div>

					<div  style="display:flex;flex-direction:row">
					<span><strong>4</strong></span>&ensp; #
					</div>
					<img src="img/FriendsPlus/Page29/E4/1.png" style="width: 12cm"/>
					   	
					</div>
        `,
        answer: [
          `His best friend can't speak French, can she?`,
          `We haven't seen this film before, have we?`,
          `Their parents don't like swimming, do they?`,
          `You're going on holiday to Greece, aren't you?`,
        ],
      },
    ],
  },
};

export default json;
