import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/TypeIn/MatchDots";

const json = {
  1: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P57-E1",
    audio: "",
    video: "",
    recorder: true,
    exerciseKey: "",
    component: T6v2,
    // maxLength: 1,
    inputSize: 280,
    textareaStyle: {
      // background: 'none',
      width: 700,
      fontSize: 22,
      height: 130,
    },
    titleQuestion: [
      {
        num: "1",

        title: `
        <span style='color:#574b94' ><b>SPEAKING!</b></span> Look at the photo. What is each object for? Do you use any of them?
												`,
        color: "#574b94",
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
			<div style='margin-left:200px'><img src="img/FriendsPlus/Page57/E1/1.jpg"/></div>
			<div style='margin-left: 150px'><textarea id='1' rows='3'></div>
						
												`,
        answer: [],
      },
    ],
  },
  2: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P57-E2",
    audio: "",
    // hideBtnFooter:true,
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page57/Key/E2answerKey.png",
    component: T6v2,
    // maxLength: 1,
    inputSize: 280,
    textareaStyle: { width: "470px" },
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "20px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "2",

        title: `
        <span style='color:#574b94' ><b>Reading!</b></span> Read a product review. What didn’t Mark like about the product at first?
												`,
        color: "#574b94",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
			<div style='display:flex'>
      <img src="img/FriendsPlus/Page57/E2/1.jpg" />
      <div style='margin-left:15px'>
      <u><i>Answer:</i></u>
      <textarea id='0' rows='1'></textarea>
      </div>
      </div>
			
						
												`,
        answer: [`the price`],
      },
    ],
  },
  3: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P57-E3",
    audio: "",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page57/Key/E3answerKey.png",
    component: Circle_Write,
    // maxLength: 1,
    inputSize: 280,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "20px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "3",

        title: `
        Read the product review again. Correct the sentences.
 
												`,
        color: "#574b94",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 100, fontSize: 19, textAlign: "center" },
        answers: [""],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 2px",
          // borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [
          `heat / light`, //1
          `light / heavy`, //1
          `recycle / reduce`, //1
          `cheap / expensive`, //1
        ],
        answers: ["0-4", `1-0`, `2-4`, `3-4`],
        initialValue: [],
      },
      Layout: `
      <div style='display: flex'>
      
      
      <img src="img/FriendsPlus/Page57/E2/1.jpg" style='width: 15cm; height: 15cm; margin-right: 20px' />
      
        <div style='line-height:2'>
          
          <div><b>1</b>&ensp; The bottle uses <input id='0' type='Circle'/> to remove bacteria and viruses.</div>

          <div><b>2</b>&ensp; It’s <input id='1' type='Circle'/> to carry when hiking or climbing.</div>
          
          <div><b>3</b>&ensp; Mark likes the fact that when you use this bottle, you <input id='2' type='Circle'/> pollution.</div>
          
          <div><b>4</b>&ensp; At first Mark thought the bottle was <input id='3' type='Circle'/>. </div>
    
        </div>
        </div>
      `,
    },
  },
  4: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P57-E4",
    audio: "Audios/1-22 Friends Plus 8_WB.mp3",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page57/Key/E4answerKey.png",
    stylesTextInput: { borderBottom: "2px dotted", height: 29, fontSize: 23 },
    inputSize: 250,
    maxLength: 15,
    isHiddenCheck: true,
    textAlign: "center",
    // checkUppercase: true,
    titleQuestion: [
      {
        num: "4",
        title:
          '<headphone name="22"></headphone >&ensp; <span>Listen to Zara and her dad talking about their hiking trip.<br> Which three words do they <u>not</u> mention?</span>',

        color: "#574b94",
      },
    ],
    questions: [
      {
        title: `
			<div style='display:flex; font-size: 23px; line-height: 50px'>
        <div>
          <div style='display:flex'><b>1</b>&ensp;jacket </div>
          <div style='display:flex'><b>2</b>&ensp;volcano </div>
          <div style='display:flex'><b>3</b>&ensp;falls </div>
          <div style='display:flex'><b>4</b>&ensp;waves </div>
        </div>
        <div style='margin-left:100px'>
          <div style='display:flex'><b>5</b>&ensp;damage </div>
          <div style='display:flex'><b>6</b>&ensp;set off </div>
          <div style='display:flex'><b>7</b>&ensp;waterproof </div>
          <div style='display:flex'><b>8</b>&ensp;natural </div>
        </div>
      
      </div>
       <div style=''> 
       <i><u>Answer:</u></i><br>
       #;&ensp;
       #;&ensp;
       #
       </div> 
       `,
        answer: [
          `waves / damage / natural`,
          `waves / damage / natural`,
          `waves / damage / natural`,
        ],
      },
    ],
  },
  5: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P57-E5",
    audio: "Audios/1-22 Friends Plus 8_WB.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page57/Key/E5answerKey.png",
    component: T6v2,
    // maxLength: 1,
    inputSize: 80,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "22px",
      // fontWeight: "bold",
      borderBottom: "2px dotted",
      height: 29,
      paddingBottom: 10,
    },
    titleQuestion: [
      {
        num: "5",

        title: `
        <headphone name="22"></headphone >&ensp; Listen again and write <i>true</i> or <i>false</i>.
		`,
        color: "#574b94",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
      <div style='line-height:50px; font-size: 22px'>
        <div style='display:flex; align-items: center;'><b>1.</b>&ensp; Zara’s dad has already read her email. #</div>
        <div style='display:flex; align-items: center;'><b>2.</b>&ensp; Zara’s dad has found a place for a hike in the summer. #</div> 
        <div style='display:flex; align-items: center;'><b>3.</b>&ensp; Zara’s plan is to hike across the valleys and mountains in Iceland. #</div> 
        <div style='display:flex; align-items: center;'><b>4.</b>&ensp; The hiking trail is sixty-five kilometres long. #</div> 
        <div style='display:flex; align-items: center;'><b>5.</b>&ensp; Zara has recently bought a special water bottle. #</div>
        <div style='display:flex; align-items: center;'><b>6.</b>&ensp; Zara’s family haven’t saved enough money to buy everything they want. #</div>    


      </div>
        `,
        answer: [`false`, `false`, `true`, `false`, `false`, `true`],
      },
    ],
  },
  6: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P57-E6",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page56/Key/E6answerKey.png",
    component: T6v2,
    // maxLength: 1,
    inputSize: 150,
    textareaStyle: { width: "500px" },
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "20px",
      textAlign: "center",
      fontWeight: "bold",
      // border:'dotted',
    },
    titleQuestion: [
      {
        num: "6",
        title: `
        A TASK Imagine you have bought a new piece of equipment for a hike in the mountains. Write the review.
        `,
        color: "#6A5EA1",
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
      <div style='line-height:2'>
        <div>
        <b>B THINK AND PLAN</b>
        <div><b>1</b>&ensp; What is it? What does it do? Why is it special?</div>
        <div><b>2</b>&ensp; What do you like about it?</div>
        <div><b>3</b>&ensp; What is the biggest disadvantage of this product?</div>
        </div>

        <div>
        <b>C WRITE Use the text in exercise 2 and the writing guide.</b>
        <div style='display:flex  '>
        <img src="img/FriendsPlus/Page57/E6/1.jpg" style='width:45%' />
          <div style='margin-left:15px'>
          <i>ANSWER:</i> 
          <textarea id='0' rows='5'></textarea></div>
        </div>
          
        </div>
      
  
  


      </div>
        `,
        answer: [
          `amazing`,
          `breakfast`,
          `breakfast`,
          `baggy`,
          `patterned`,
          `delicious`,
        ],
      },
    ],
  },
};

export default json;
