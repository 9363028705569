import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 8",
    id: "FP8-WB-U8-P51-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page52/Key/E1answerKey.png",
    component: Circle_Write,
    titleQuestion: [
      {
        num: "1",
        title: `
        <b>Choose the correct word.</b>
			  `,
        color: "rgb(220,44,55)",
        star: 1,
      },
    ],
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: { width: 100, fontSize: 19, textAlign: "center" },
        answers: [""],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 2px",
          // borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [
          `directors / nominations`, //1
          `adapted / written`, //1
          `nominated / starred`, //1
          `awarded / written`, //1
          `adapted / awarded`,
          `Actor / Nomination`,
        ],
        answers: ["0-0", "1-0", "2-0", "3-4", `4-4`, `5-0`],
        initialValue: [],
      },
      Layout: `
      <div>Jennifer Lawrence <span style=' border: 2px solid #0F1C3F;border-radius: 50%;box-shadow: 0.375em 0.375em 0 0 rgba(15, 28, 63, 0.125);'> <b>stars in</b> </span> <b>/ directs</b> The Hunger Games films.</div>
      <div style='display:flex'>
        <div style ='line-height:3'>
            <div><b>1</b>&ensp; Peter Jackson is one of the most successful film <input id='0' type='Circle'/> in the world. </div>
           
            <div><b>2</b>&ensp; The Fault in Our Stars film was <input id='1' type='Circle'/> from a book by John Green. </div>

            <div><b>3</b>&ensp; The film Titanic was <input id='2' type='Circle'/> for fourteen Oscars, and won eleven awards. </div>

            <div><b>4</b>&ensp; The original Spider-Man graphic novel was <input id='3' type='Circle'/> by Stan Lee. </div>

            <div><b>5</b>&ensp; Katharine Hepburn was <input id='4' type='Circle'/> four Oscars in her acting career. </div>

            <div><b>6</b>&ensp; <input id='5' type='Circle'/> Leonardo DiCaprio won an Oscar in 2016 </div>
           </div>
      </div>

                        </div>


        
      `,
    },
  },
  2: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P52-E2",
    // audio: "Audios/2.04.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page52/Key/E2answerKey.png",
    component: T6v2,
    maxLength: 11,
    inputSize: 180,
    stylesTextInput: {
      background: "transparent",
      border: "none",
      textAlign: "center",
      fontSize: "25px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "2",

        title: `
        Complete the review with the correct noun or verb form of the words in exercise 1.
									`,
        color: "rgb(220,44,55)",
        star: 2,
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='position: relative; margin-left:-120px'>
        <div style='display:flex'>
            <img src="/img/FriendsPlus/Page52/E2/1.jpg" style='width:90%; margin-left:120px'  >
            <img src="/img/FriendsPlus/Page52/E2/2.jpg" style='width: 15cm; height: 13cm'  >
        </div>
        <div style=" position: absolute; top: 125px; left: 217px; "><input id='0' style="width:0px;boder-bottom:none;"></input></div>
        <div style=" position: absolute; top: 162px; left: 588px; "><input id='1' style="width:0px"></input></div>
        <div style=" position: absolute; top: 200px; left: 412px; "><input id='2' style="width:0px"></input></div>
        <div style=" position: absolute; top: 236px; left: 168px; "><input id='3' style="width:0px"></input></div>
        <div style=" position: absolute; top: 310px; left: 166px; "><input id='4' style="width:0px"></input></div>
        <div style=" position: absolute; top: 385px; left: 168px; "><input id='5' style="width:0px"></input></div>
					
        `,
        answer: [
          "awarded",
          "adapted",
          "Actor",
          "star",
          "director",
          `nominations`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P52-E3",
    audio: "Audios/1-19 Friends Plus 8_WB.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page52/Key/E3answerKey.png",
    component: T6v2,
    maxLength: 5,
    // inputSize: 90,
    stylesTextInput: {
      background: "transparent",
      border: "none",
      textAlign: "center",
      fontSize: "25px",
      fontWeight: "bold",
    },
    titleQuestion: [
      {
        num: "3",
        star: 2,

        title: `
        <headphone name="19"></headphone > 
        <span style='margin-left:10px'> Listen to four people talking about their favourite book or film when they were teenagers. Complete the table.</span>
									`,
        color: "rgb(220,44,55)",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
						
        <div style='position: relative;'>
        <div style='display:flex'>
            <img src="/img/FriendsPlus/Page52/E3/1.jpg" style='width:80%; margin-left:120px'  >
        </div>
        <div style=" position: absolute; top: 95px; left: 596px; width:100px; "><input id='0' style="width:40px;"></input></div>
        <div style=" position: absolute; top: 162px; left: 600px; width:100px; "><input id='1' style="width:40px"></input></div>
        <div style=" position: absolute; top: 164px; left: 733px; width:160px; "><input id='2' style="width:100px"></input></div>
        <div style=" position: absolute; top: 233px; left: 600px; width:100px; "><input id='3' style="width:90px"></input></div>
        <div style=" position: absolute; top: 233px; left: 733px; width:160px; "><input id='4' style="width:250px"></input></div>
        <div style=" position: absolute; top: 300px; left: 600px; width:100px; "><input id='5' style="width:90px"></input></div>
        <div style=" position: absolute; top: 300px; left: 733px; width:160px;"><input id='6' style="width:250px"></input></div>
									`,
        answer: [`15`, `13`, `film`, `16`, `film`, `14`, `book`],
      },
    ],
  },
  4: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P52-E4",
    audio: "Audios/1-19 Friends Plus 8_WB.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page52/Key/E4answerKey.png",
    component: T6v2,
    // maxLength: 1,
    inputSize: 50,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
      borderStyle: "dotted",
      textTransform: "uppercase",
      fontWeight: "bold",
      textAlign: "center ",
    },
    titleQuestion: [
      {
        num: "4",
        star: 2,
        title: `
        <headphone name="19"></headphone > 
       <span style='margin-left:10px'> Which speaker makes each comment? Write L (Les), A (Angela), S (Sean) or C (Caroline). Then listen again and check.</span>
        `,
        color: "rgb(220,44,55)",
        // prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
          <div style='line-height:2'>
            My brother gave it to me. <b>L</b>
            <div><b>1.</b> I was surprised that I liked it. #</div>
            <div><b>2.</b> One of the actors died not long after.#</div>
            <div><b>3.</b> I’ve only ever had one favourite book. #</div>
            <div><b>4.</b> I read it many times. #</div>
            <div><b>5.</b> It was well directed. #</div>
            <div><b>6.</b> The stars of the film were very young. #</div>
          
          </div>

        
        `,
        answer: [`S`, `A`, `C`, `L`, `S`, `A`],
      },
    ],
  },
  5: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P52-E5",
    // audio: "Audios/1-19 Friends Plus 8_WB.mp3",
    video: "",
    // recorder: true,
    exerciseKey: "",
    component: T6v2,
    // maxLength: 1,
    inputSize: 50,
    textareaStyle: { width: "1000px" },
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "25px",
      borderStyle: "dotted",
      // fontWeight: "bold",
      // textAlign:'center ',
    },
    titleQuestion: [
      {
        num: "5",
        star: 3,
        title: `
        Interview a parent or grandparent about 
        a book or film they enjoyed when they were 
        young. Take notes, and write a short description of 
        the book or film. Include this information:
        `,
        color: "rgb(220,44,55)",
        // prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
          <div style='line-height:2'>
            <ul>
              <li>how they felt about the book / film and why</li>
              <li>how old they were when they read / saw it</li>
              <li>when it was written / made</li>
              <li>the main characters / actors</li>
              <li>the writer / director</li>
            </ul>
            <div style='width:1000px; color: gray'> <b><i>My grandma’s favourite film was the musical The Sound of
            Music. It was made in 1965 and she was sixteen when she
            saw it at the cinema. The story made her feel both sad and
            happy and she loved the music. The main character, 
            Maria, is played by Julie Andrews. The film was directed
            by Robert Wise.</i></b></div>
            <span><textarea id="0" rows="6"></textarea></span>
          </div>

        
        `,
        answer: [],
      },
    ],
  },
};

export default json;
