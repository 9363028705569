import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Grammar Builder and Reference",
    id: "FGF11-GBAR-P128-U8.3-E1",
    audio: "",
    video: "",
    titleQuestion: [
      {
        num: "1",
        title: `Match 1–6 with a–f.
`,
        color: "rgb(46,180,106)",
        star: 1,
        // prefix: { icon: [''], text: '' },
      },
    ],

    component: MatchDots,
    exerciseKey: "img/FriendsPlus/Page31/E1/Key/E1answerkey.png",
    stylesTextInput: { borderBottom: "dotted" },
    inputSize: 700,
    fontSize: 55,
    maxLength: 200,
    // checkUppercase: true,

    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "480px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "60px",
              left: "480px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "105px",
              left: "480px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "150px",
              left: "480px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "195px",
              left: "480px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "238px",
              left: "480px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "20px",
              left: "610px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "60px",
              left: "610px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "105px",
              left: "610px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "150px",
              left: "610px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "195px",
              left: "610px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 10
          {
            boxMatchStyle: {
              position: "absolute",
              top: "238px",
              left: "610px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 11
        ],
        answers: ["1-10", "2-9", "3-6", "11-4", "5-7", "0-8"],
        initialValue: [],
      },
      Layout: `
      <div style='display:flex; width:1200px; margin-bottom: 100px'>
        <input id='0' style="margin-bottom:20px" type= 'boxMatch' />
        <input id='1' style="margin-bottom:20px" type= 'boxMatch' />
        <input id='2' style="margin-bottom:20px" type= 'boxMatch' />
        <input id='3' style="margin-bottom:20px" type= 'boxMatch' />
        <input id='4' style="margin-bottom:20px" type= 'boxMatch' />
        <input id='5' style="margin-bottom:20px" type= 'boxMatch' />
        <input id='6' style="margin-bottom:20px" type= 'boxMatch' />
        <input id='7' style="margin-bottom:20px" type= 'boxMatch' />
        <input id='8' style="margin-bottom:20px" type= 'boxMatch' />
        <input id='9' style="margin-bottom:20px" type= 'boxMatch' />
        <input id='10' style="margin-bottom:20px" type= 'boxMatch' />
        <input id='11' style="margin-bottom:20px" type= 'boxMatch' />
        <div style="display:flex; font-size:29px">
					<div style='margin-right: 200px'>
						<b>1</b>&emsp;I brought an 
            umbrella in.<br>
						<b>2</b>&emsp;You use this to<br>
						<b>3</b>&emsp;My parents got me 
            a laptop so<br>
						<b>4</b>&emsp;Give someone an 
            extra key, in<br>
            	<b>5</b>&emsp;They’re making a 
              new law in <br>
              <b>6</b>&emsp;We’re getting a 
              larger tent so
				 
					</div>

						<div style="">

								<b>a</b>&emsp;case you lose yours<br>
								<b>b</b>&emsp;that we can 
                invite our friends 
                camping.<br>
								<b>c</b>&emsp;case it rains later.<br>
								<b>d</b>&emsp;that I can do 
                homework on it.<br>
                <b>e</b>&emsp;that I can do 
                homework on it.<br>


                <b >f</b>&emsp;order to reduce 
                pollution from cars. 

                </div> 
						</div>
				</div>
       

      </div>
        
      `,
    },
  },

  2: {
    unit: "Unit 3",
    id: "FP8-SB-demo-2023-U3-P31-E4",
    // audio: "Audios/1.24.mp3",
    exerciseKey: "img/FriendsPlus/Page31/E2/Key/E2answerkey.png",
    component: T6v2,
    // hideBtnFooter: true,
    inputSize: 200,
    stylesTextInput: {
      fontSize: "24px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "2",
        title: `
        Complete the sentences with one or 
        two words.
				`,
        color: "rgb(46,180,106)",
        star: 2,
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
          <div style=' display: flex;flex-direction:column; line-height: 50px'>
           <span>I got a plastic cover for my phone, in <u><strong style="text-decration:underline; color: gray">case </strong></u>
           I drop it on the floor.</span>

           <span><strong style="margin-right:30px">1</strong> I’m phoning Hasan,# that he can 
           explain what’s happened.</span>

           <span><strong style="margin-right:30px">2</strong> He went back to the shop, in # 
           exchange his coat for a smaller size.</span>

           <span><strong style="margin-right:30px">3</strong>Give me your phone number, #
           case I get lost and need to call you.</span>

           <span><strong style="margin-right:30px">4</strong> Hannah stayed up late # that she 
           could study for her exam.</span>

           <span><strong style="margin-right:30px">5</strong> I use this website # order to share 
           photos with all my friends.</span>

           <span><strong style="margin-right:30px">6</strong> We left the party early # catch our 
           train.</span>

          </div>
				`,
        answer: [`so`, `order to`, `in`, `so`, `in`, `to`],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "FP8-SB-demo-2023-U2-P29-E13",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page31/E3/Key/E3answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title: `
				Complete the reviews with the correct 
words. 
				`,
        color: "rgb(103,178,106)",
        star: 2,
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "-40px",
          fontWeight: 600,
          fontStyle: "italic",
          color: "",
          // fontSize: 27,
          borderRadius: "50%",
          // marginInline: '-1px',
        },
        selectWordStyle: { border: "2px solid rgb(0, 173, 238)" },
        limitSelect: 1,
        listWordsStyle: {},
        listWords: [
          `found / felt / saw`,
          `for / to / by`,
          `problem / disadvantage / negative`,
          `quite / very / exactly`,
          `everything / something / nothing`,
          `disadvantage / advantage / positive`,
        ],
        answers: ["0-0", "1-4", "2-0", "3-8", "4-4", "5-0"],
        initialValue: [],
      },
      Layout: `
				<div style='display: flex; flex-direction: row; '>
          <div style="width:600px; margin-right:30px">
          <img src="img/FriendsPlus/Page31/E3/1.png"/> <br/>

          You know when you hear a song you like, but 
you don’t know its name? It’s annoying, isn’t 
it? Well, not any more! You’re going to <strong>hate /</strong> <strong style="border:2px solid gray;border-radius:50% "> love /</strong> <strong>listen</strong> Shazam. This smartphone app 
identifies any music and I <sup>1</sup><input id='0' type='Circle' />
it very easy <sup>2</sup><input id='1' type='Circle' /> use. You record the 
music and the app tells you the song. Then it 
links you to an online store so that you can buy 
it. My only <sup>3</sup><input id='2' type='Circle' />
with it is that sometimes it offers you the same 
song, but by a different artist. I’ve spent a lot 
more money on music since I got Shazam, too! 
But overall, I’d definitely recommend this app.
          
          
          </div>

          <div style="width:600px">
          <img src="img/FriendsPlus/Page31/E3/2.png"/> <br/>

          Twitter is a social media website where users 
send tweets, or short messages. It does 
<sup>4</sup><input id='3' type='Circle' />  what the adverts say. 
You can write your own messages, or sign up 
to follow other people’s tweets. You can only 
use 140 characters in a message, so it isn’t 
<sup>5</sup><input id='4' type='Circle' />  that’s good 
for long conversations! Probably the biggest 
<sup>6</sup><input id='5' type='Circle' />  with 
Twitter is that 140 characters aren’t enough. It’s 
easy to get the wrong idea from a tweet, and I 
don’t really like that.


          </div>
				
				</div>
			  `,
    },
  },
  4: {
    unit: "Unit 3",
    id: "FP8-SB-demo-2023-U3-P31-E5",
    // exerciseKey: 'img/FriendsPlus/Page31/E3/Key/answerKey.png',
    component: T6,
    finished: {
      text: `
      Think about two places of interest in Việt Nam. Use the key phrases to talk about which one you'd prefer to see and why.
      `,
    },
    hideBtnFooter: true,
    inputSize: 230,
    stylesTextInput: {
      fontSize: "24px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "4",
        title: `Write a review of a smartphone app or 
        a website that you have used. Include answers to 
        these questions:
				`,
        color: "rgb(46,180,106)",
        star: 3,
      },
    ],
    textareaStyle: {
      width: 1100,
    },
    isHiddenCheck: true,
    questions: [
      {
        title: `
           <ul>
            <li>What is it for?</li>
            <li>How easy / difficult is it to use?</li>
            <li>What do you like / dislike most about it?</li>
            <li>How many stars will you give it?</li>
            <li>Do you think people should buy / use it?</li>
           </ul>

           Use the reviews in exercise 3 to help you. Include 
clauses of purpose and the phrases on this page. 

<textarea id="0" rows=7></textarea>

				`,
        answer: [],
      },
    ],
  },
};

export default json;
