import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";

const json = {
  1: {
    unit: "Unit 6",
    id: "FP8-WB-demo-2023-U6-P38-E1",
    audio: "",
    video: "",
    recorder: true,
    exerciseKey: "img/FriendsPlus/Page38/Key/E1answerKey.png",
    component: T6v2,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      textAlign: "center",
      fontSize: "23px",
    },
    hintBox: [
      {
        src: [
          `<s>boading</s>`,
          `<s>holiday</s>`,
          `-leavers`,
          `mixed`,
          `primary`,
          `rules`,
          `secondary`,
          `single-sex`,
          `uniform`,
        ],
        borderColor: "#0067B4",
        width: 1000,
      },
    ],

    isHiddenCheck: true,
    inputSize: 200,
    maxLength: 10,
    titleQuestion: [
      {
        num: "1",
        title: `
				Complete the table with the words.
				`,
        color: "#0067B4",
        star: 1,
      },
    ],
    questions: [
      {
        title: `
					<div style='margin-top: 10px; display: flex; gap: 20px; font-size: 23px'>
							<div>
							<hintBox id='0'></hintBox></div>
              <style>
              .wrapper {
                text-align: center;
              }
              table{
                margin: 0 auto;
                padding: 10px;
                
                border-collapse: separate;
                border-spacing: 0;
                width:800px;
              }
              td, tr{
                border: 1px solid rgb(0,103,180);
                padding: 10px 20px;
              }
              .center-content {
               text-align: center;
              background-color: white;
              width: 500px;
              margin: 0 auto;
  }
              
              tr:first-child td:first-child{
                border-top-left-radius: 10px;
              }
              tr:last-child td:first-child{
                border-bottom-left-radius: 10px;
              }
              tr:first-child td:last-child{
                border-top-right-radius: 10px;
              }
              tr:last-child td:last-child{
                border-bottom-right-radius: 10px;
              }
              
              </style>
					</div>

          <div class='wrapper' style='font-size: 23px; line-height: 40px'>
          <table>
            <tr>
             <td colspan="2" style="background-color:rgb(200,212,238)">
                      <div class="center-content">
                        School compound nouns
                      </div>
                    </td>
            </tr>
            <tr>
          <td>
                      <span style="text-decoration: underline; color: gray">&ensp;boading&ensp;</span>
                      <br>
                      1&ensp;<input/><br>
                      2&ensp;<input/><br>
                      3&ensp;<input/><br>
                      4&ensp;<input/>
                    </td>
                    <td>School</td>      
            </tr>
            <tr>
             <td>School</td>
                    <td>
                      <span style="text-decoration: underline; color: gray">&ensp;holiday&ensp;</span>
                      <br>
                      5&ensp;<input/><br>
                      6&ensp;<input/><br>
                      7&ensp;<input/>
                    </td>
            </tr>
          </table>
        </div>
        `,
        answer: [
          `mixed`,
          `primary`,
          `secondary`,
          `single-sex`,
          `-leavers`,
          `rules`,
          `uniform`,
        ],
      },
    ],
  },
  2: {
    unit: "Unit 6",
    id: "FP8-WB-demo-2023-U6-P38-E2",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page38/Key/E2answerKey.png",
    component: T6v2,
    maxLength: 20,
    inputSize: 230,
    stylesTextInput: {
      // background: "rgb(225,226,228)",
      fontSize: "22px",
      // borderButoom: '1px solid',
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "2",
        title: `
        Match the compound nouns in exercise 1 with the definitions.
        `,
        color: "#0067B4",
        star: 2,
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
    <div style='line-height:2'>
    <div>the times of the year when you don’t go to school <b> <u>school holidays</u></b></div>
    <div style ='display:flex'>
    <b>1</b>
    <div style='margin-left:10px'>
    a place where you study from the age of around six to eleven <input/>
    </div>
    </div>
    <div style ='display:flex'>
    <b>2</b>
    <div style='margin-left:10px'>
    the clothes you have to wear at school  <input/>
    </div>
    </div>
    <div style ='display:flex'>
    <b>3</b>
    <div style='margin-left:10px'>
    a place where you live and study with other students  <input/>
    </div>
    </div>
    <div style ='display:flex'>
    <b>4</b>
    <div style='margin-left:10px'>
    young people who are finishing their final year of school  <input/>
    </div>
    </div>
    <div style ='display:flex'>
    <b>5</b>
    <div style='margin-left:10px'>
    a place where only girls, or only boys, study  <input/>
    </div>
    </div>
    <div style ='display:flex'>
    <b>6</b>
    <div style='margin-left:10px'>
    a place where you study from the age of around twelve to sixteen  <input/>
    </div>
    </div>
    <div style ='display:flex'>
    <b>7</b>
    <div style='margin-left:10px'>
    they tell you how to act and what you can’t do while at school <input/>
    </div>
    </div>
    <div style ='display:flex'>
    <b>8</b>
    <div style='margin-left:10px'>
    a place where girls and boys study together  <input/>
    </div>
    </div>
    </div>
        
        `,
        answer: [
          "primary school",
          "school uniform",
          "boarding school",
          "school-leavers",
          "single-sex school",
          "secondary school",
          "school rules",
          "mixed school",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 6",
    id: "FP8-WB-demo-2023-U6-P38-E3",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page38/Key/E3answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title:
          "Choose the correct words to complete what Ali and Phoebe say about school. ",
        color: "#0067B4",
        star: 2,
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 5,
          borderRadius: "50%",
          border: "2px solid black", // Sửa giá trị border thành "1px solid #000000"
          borderColor: "transparent",
          // color: "#000000",
        },
        selectWordStyle: {
          padding: 5,
          borderColor: "#0067B4",
        },

        limitSelect: 1,
        listWords: [
          "do / make / wirte", //0
          "write / pass / make", //1
          "do / be / get", //2
          "go / get / be", //3
          "make / take / obey", //4
          "pass / get / do", //5
          "failed / fell / lost", //6
          "study / take / help", //7
          "enrol / join / sign", //8
        ],
        answers: [
          "0-0",
          "1-4",
          "2-8",
          "3-4",
          "4-8",
          "5-4",
          "6-0",
          "7-4",
          "8-0",
        ],
        initialValue: [],
      },
      Layout: `
       <div style='display:flex;'>
       <div>
       School can be hard work, but I like it.
       I always <b>do / <span style="display: inline-block; border: 2px solid black; border-radius: 50%; padding: 4px;">go to</span> / assist</b> 
       <br> lessons and  <sup>1</sup> <b><input id='0' type='Circle' /></b> homework on time.
         It’s important  <br> for me to <sup>2</sup><b><input id='1' type='Circle' /></b>  my exams
          because I want to be <br> a vet.  I need to  <sup>3</sup><b><input id='2' type='Circle' /></b> good
           qualifications <br> so that I can <sup>4</sup> <b><input id='3' type='Circle' /></b> into university one day. 
       </div>
      <div>
      <img src='img/FriendsPlus/Page38/E3/1.jpg' style='max-width:60%; margin-left:10px;'>
      </div>
       </div>
       <div style='display:flex'>
       <div>
       <img src='img/FriendsPlus/Page38/E3/2.jpg' style='max-width:70%'>
       
       </div>

       <div>
       School can be hard work, but I like it. 
       I’ve been at boarding <br> school since I was eight.
        Living away from home is OK, <br> but we have to
         <sup>5</sup> <b><input id='4' type='Circle' /></b> rules all the time.<br>
          I don’t always <sup>6</sup> <b><input id='5' type='Circle' /></b> 
           good marks in tests, <br> and last year,
           I <sup>7</sup> <b><input id='6' type='Circle' /></b> a maths exam. <br>
            This year, I need to <sup>8</sup> <b><input id='7' type='Circle' /></b> extra classes <br>
             and <sup>9</sup> <b><input id='8' type='Circle' /></b> myself in homework club to do better! 
       </div>
     
       </div>

       


      `,
    },
  },
  4: {
    unit: "Unit 6",
    id: "FP8-WB-demo-2023-U6-P38-E4",
    audio: "",
    video: "",
    // recorder: true,
    // exerciseKey: "img/FriendsPlus/Page38/Key/E2answerKey.png",
    component: T6v2,
    maxLength: 200,
    inputSize: 750,
    stylesTextInput: {
      // background: "rgb(225,226,228)",
      fontSize: "22px",
      // borderButoom: '1px solid',
      textAlign: "left",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
        Answer the questions with your own ideas. Give reasons for your answers. 
        `,
        color: "#0067B4",
        star: 3,
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
    <div style='line-height:2'>
    <div>Do you take any classes after school? <br>
     <u><b style='color: gray'> Yes, I take English, piano and dance classes because I enjoy being creative. </b></u></div>
    <div style ='display:flex'>
    <b>1</b>
    <div style='margin-left:10px'>
    How is secondary school different from primary school? <br> <input/>
    </div>
    </div>
    <div style ='display:flex'>
    <b>2</b>
    <div style='margin-left:10px'>
    Which of your school rules do students find difficult to obey sometimes?   <br> <input/>
    </div>
    </div>
    <div style ='display:flex'>
    <b>3</b>
    <div style='margin-left:10px'>
    What do you think students need to do to get good marks at school?  <br> <input/>
    </div>
    </div>
    <div style ='display:flex'>
    <b>4</b>
    <div style='margin-left:10px'>
    What career are you interested in? What qualifications do you need for that career?  
    <br> <input/>
    </div>
    </div>
    </div>

   
        
        `,
        answer: [],
      },
    ],
  },
};

export default json;
