import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6v2 from "../../components/ExcerciseTypes/TypeIn/T6v2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P51-E1",
    // audio: "Audios/2.03.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page51/Key/E1answerKey.png",
    component: T6v2,
    maxLength: 6,
    inputSize: 90,
    stylesTextInput: {
      background: "transparent",
      border: "none",
      fontSize: "20px",
      // borderStyle:'dotted',
      textAlign: "center",
    },
    hintBox: [
      {
        src: [
          "<s>am not</s>",
          "are",
          "given",
          "isn’t",
          "shown",
          "was",
          "weren't",
        ],
        borderColor: "#1dbfd7",
        width: 500,
      },
    ],
    titleQuestion: [
      {
        num: "1",

        title: `
        Complete the table with the words.
        `,
        color: "rgb(27, 191, 215)",
        star: 1,
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <hintBox id='0' ></hintBox>
        <div style='position: relative;'>
        <div style='display:flex'>
            <img src="/img/FriendsPlus/Page51/E1/1.jpg" style='width:70%'  >
        </div>
        <div style=" position: absolute; top: 100px; left: 333px; "><input id='0' style="width:0px;boder-bottom:none;"></input></div>
        <div style=" position: absolute; top: 164px; left: 152px; "><input id='1' style="width:0px"></input></div>
        <div style=" position: absolute; top: 221px; left: 212px; "><input id='2' style="width:0px"></input></div>
        <div style=" position: absolute; top: 164px; left: 334px; "><input id='3' style="width:0px"></input></div>
        <div style=" position: absolute; top: 254px; left: 188px; "><input id='4' style="width:0px"></input></div>
        <div style=" position: absolute; top: 286px; left: 160px; "><input id='5' style="width:0px"></input></div>
        
        
        
        
    
      </div>

        
        `,
        answer: ["given", `are`, `weren't`, `shown`, `isn't`, `was`],
      },
    ],
  },
  2: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P51-E2",
    // audio: "Audios/2.03.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page51/Key/E2answerKey.png",
    component: T6v2,
    maxLength: 90,
    inputSize: 800,

    stylesTextInput: {
      background: "transparent",
      // border: 'none',
      fontSize: "22px",
      // borderStyle:'dotted',
    },
    titleQuestion: [
      {
        num: "2",

        title: ` Write sentences using the present or past passive.
        `,
        color: "rgb(27, 191, 215)",
        star: 2,
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='font-size: 22px; line-height: 45px'>
          <div>
            <div>This film / make / in New Zealand last year (past)</div>
            <div><u style="text-decoration: underline; color: gray">&ensp;This film was made in New Zealand last year.&ensp;</u></div>
          </div>
          <div style="margin-top:10px">
          <b>1.</b> <span>Many films / download / every day (present)</span>
            #
          </div>
          <div>
          <b>2.</b> <span>The actors / not tell / the plot until the first day of work (past)</span>
            #
          </div>
          <div>
          <b>3.</b> <span>Actors / not allow / to talk about the films until they are released (present) </span>
            #
          </div>
          <div>
          <b>4.</b> <span>Film stars / know / all around the world (present)</span>
            #
          </div>
          <div>
          <b>5.</b> <span>All the food on the set / eat / by the workers (past)</span>
            #
          </div>
          <div>
          <b>6.</b> <span>Some critics / pay / to write good reviews of the films (past)</span>
            #
          </div>
          
          
        </div>

        
        `,
        answer: [
          `Many films are downloaded every day.`,
          `The actors weren't told the plot until the first day of work.`,
          `Actors aren't allowed to talk about the films until they are released.`,
          `Film stars are known all around the world.`,
          `All the food on the set was eaten by the workers.`,
          `Some critics were paid to write good reviews of the films.`,
        ],
      },
    ],
  },
  3: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P51-E3",
    // audio: "Audios/2.03.mp3",

    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page51/Key/E3answerKey.png",
    component: T6v2,
    // maxLength: 6,
    inputSize: 700,
    stylesTextInput: {
      background: "transparent",
      // border: 'none',
      fontSize: "25px",
      borderStyle: "dotted",
    },
    titleQuestion: [
      {
        num: "3",

        title: `
        <span>Rewrite the sentences using the passive. Start each sentence with the words in <b>bold</b>.</span>
        `,
        color: "rgb(27, 191, 215)",
        star: 2,
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style="line-height:2">
          <div>
          <b>1.</b> <span>They didn’t pay <b>the actors</b> on time.</span>
            <div><input id="0" rows="2"></input></div>
          </div>
          <div>
          <b>2.</b> <span>Someone broke <b>these plates</b> in the restaurant.</span>
            <div><input id="1" rows="2"></input></div>
          </div>
          <div>
          <b>3.</b> <span>We don’t speak <b>Russian</b> in this shop. </span>
            <div><input id="2" rows="2"></input></div>
          </div>
          <div>
          <b>4.</b> <span>They didn’t tell <b>Amy</b> about this!</span>
            <div><input id="3" rows="2"></input></div>
          </div>
          <div>
          <b>5.</b> <span>They give <b>special visitors</b> a blue ticket.</span>
            <div><input id="4" rows="2"></input></div>
          </div>
        </div>
        
        `,
        answer: [
          `The actors weren't paid on time.`,
          `These plates were broken in the restaurant.`,
          `Russian isn't spoken in this shop`,
          `Amy wasn't told about this!`,
          `Special visitors are given a blue ticket.`,
        ],
      },
    ],
  },

  4: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P51-E4",
    audio: "",
    video: "",
    // recorder: true,
    exerciseKey: "img/FriendsPlus/Page51/Key/E4answerKey.png",
    component: T6v2,
    maxLength: 12,
    inputSize: 150,
    stylesTextInput: {
      background: "transparent",
      border: "none",
      fontSize: "22px",
      textAlign: "center",
    },
    titleQuestion: [
      {
        num: "4",
        title: `
        Complete the blog post with the correct form of the verbs in brackets.
        `,
        color: "rgb(125,167,216)",
        // prefix: { icons: ["far fa-comment"], text: "USE IT!" },
        star: 2,
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        
        <div style='position: relative;'>
        <div style='display:flex'>
        <img src="img/FriendsPlus/Page51/E4/1.jpg" style='width: 70%;margin-left: 100px;' />
        </div>
        <div style=" position: absolute; top: 141px; left: 513px; "><input id='0' style="width:0px"></input></div>
        <div style=" position: absolute; top: 174px; left: 524px; "><input id='1' style="width:0px"></input></div>
        <div style=" position: absolute; top: 238px; left: 418px; "><input id='2' style="width:0px"></input></div>
        <div style=" position: absolute; top: 306px; left: 545px; "><input id='3' style="width:0px"></input></div>
        <div style=" position: absolute; top: 373px; left: 420px; "><input id='4' style="width:0px"></input></div>
        <div style=" position: absolute; top: 407px; left: 442px; "><input id='5' style="width:0px"></input></div>
        <div style=" position: absolute; top: 508px; left: 353px; "><input id='6' style="width:0px"></input></div>
        <div style=" position: absolute; top: 541px; left: 472px; "><input id='7' style="width:0px"></input></div>
      </div>
        `,
        answer: [
          `is spent`,
          `become`,
          `are known`,
          `decided`,
          `were called`,
          `was made`,
          `were posted`,
          `were viewed`,
        ],
      },
    ],
  },
  5: {
    unit: "Unit 8",
    id: "FP8-WB-demo-2023-U8-P51-E5",
    // audio: "Audios/2.03.mp3",

    video: "",
    // recorder: true,
    hideBtnFooter: true,
    exerciseKey: "img/FriendsPlus/Page51/Key/E3answerKey.png",
    component: T6v2,
    textareaStyle: { width: "800px", height: 250 },
    // maxLength: 6,
    inputSize: 400,
    stylesTextInput: {
      // background: 'transparent',
      // border: 'none',
      fontSize: "24px",
    },
    titleQuestion: [
      {
        num: "5",

        title: `
        Write about a TV programme that you like. Use the present and past passive forms of the verbs and your own ideas.
        `,
        color: "rgb(27, 191, 215)",
        star: 3,
      },
    ],
    hintBox: [
      {
        src: [`award`, "base / set", "play", "show", "watch"],
        borderColor: "#1dbfd7",
        width: 500,
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <hintBox id='0'></hintBox>
        <div style='border-bottom: 2px solid; color: gray;'>&ensp;It was made in the USA ...</div>
        <div> <textarea id="0" rows="4"> </div>
        
        `,
        answer: [],
      },
    ],
  },
};

export default json;
